import { useCallback, useContext, useEffect, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { generatePath, useLocation } from 'react-router';

import {
  Button,
  ButtonLink,
  ConfirmationModal,
  Stack,
} from '@application/components';
import { ModalContext, NavContext } from '@application/context';
import { PrivatePage } from '@application/enums/pagesUrl';
import { RecruitmentNegotiationRoomStatus } from '@domain/graphql.types';
import { getLocalizedDescription } from '@utils/i18n-utils';
import { cn } from '@utils/lib-utils';
import { isDefined } from '@utils/type-utils';

import RejectionReasonModal from '../../request/rejection-reason/RejectionReasonModal';
import RecruitmentNegotiationContext from '../context/RecruitmentNegotiationContext';
import {
  useCompleteRecruitmentNegotiation,
  useRejectRecruitmentNegotiationRooms,
} from '../hooks';

const ActionMenu = () => {
  const { t, i18n } = useTranslation('recruitment', {
    keyPrefix: 'negotiation.menu',
  });

  const location = useLocation();
  const { isNavOpen, setIsBottomMenuOpen } = useContext(NavContext);
  const { setModal } = useContext(ModalContext);
  const {
    negotiationId,
    request,
    rooms,
    checkedRooms,
    isComparing,
    setCheckedRooms,
    setIsComparing,
    refreshRecruitmentNegotiation,
  } = useContext(RecruitmentNegotiationContext);

  const { completeRecruitmentNegotiation } =
    useCompleteRecruitmentNegotiation();
  const { rejectNegotiationRooms } = useRejectRecruitmentNegotiationRooms();

  const getOfferDetailsPath = useCallback(
    (roomId: string) => {
      const room = rooms?.find((r) => r.id === roomId);
      return room
        ? generatePath(PrivatePage.OFFER_DETAILS, {
            id: request?.id || null,
            offerId: room.offerId,
          })
        : '#';
    },
    [request?.id, rooms]
  );

  const getAccountNameList = useCallback(
    (roomIds: string[]) => {
      const filteredRooms = rooms
        ?.filter((r) => roomIds.includes(r.id))
        .map((r) => r.offer.account.name);

      return filteredRooms?.join('\n') || [];
    },
    [rooms]
  );

  const acceptedRoom = useMemo(
    () =>
      rooms?.find(
        (room) => room.status === RecruitmentNegotiationRoomStatus.Accepted
      ),
    [rooms]
  );

  const paddingLeft = useMemo(
    () => (isNavOpen ? 'lg:pl-[22rem]' : 'lg:pl-[9rem]'),
    [isNavOpen]
  );

  const agreementPath = useMemo(
    () =>
      generatePath(PrivatePage.RECRUITMENT_NEGOTIATION_AGREEMENT, {
        id: negotiationId,
      }),
    [negotiationId]
  );

  const handleDeselectAll = useCallback(
    () => setCheckedRooms([]),
    [setCheckedRooms]
  );

  const handleConfirmCreateAgreement = useCallback(
    async (roomId: string) => {
      await completeRecruitmentNegotiation({
        input: { roomId, negotiationId },
      });

      refreshRecruitmentNegotiation();
      handleDeselectAll();
    },
    [
      completeRecruitmentNegotiation,
      negotiationId,
      handleDeselectAll,
      refreshRecruitmentNegotiation,
    ]
  );

  const handleOnCreateAgreement = useCallback(
    (roomId: string) => {
      setModal({
        title: t('actions.acceptOfferTitle'),
        content: (
          <ConfirmationModal
            content={
              <Stack>
                <p>
                  <Trans
                    i18nKey="actions.acceptOfferMessage"
                    t={t}
                    values={{
                      agency: rooms?.find((room) => room.id === roomId)?.offer
                        .account.name,
                      specialty: getLocalizedDescription(
                        request?.jobSpecialty?.descriptions,
                        i18n.language
                      ),
                    }}
                  />
                </p>
              </Stack>
            }
            onCancel={() => setModal(null)}
            onConfirm={() => {
              handleConfirmCreateAgreement(roomId);
              setModal(null);
            }}
          />
        ),
        maxWidth: 'md',
        centered: true,
      });
    },
    [
      handleConfirmCreateAgreement,
      i18n.language,
      request?.jobSpecialty?.descriptions,
      rooms,
      setModal,
      t,
    ]
  );

  const handleConfirmRejectNegotiationRooms = useCallback(
    async (
      roomIds: string[],
      rejectionReason: string,
      rejectionExplanation: string
    ) => {
      setModal(null);

      await rejectNegotiationRooms({
        input: { roomIds, rejectionReason, rejectionExplanation },
      });

      refreshRecruitmentNegotiation();
      handleDeselectAll();
    },
    [
      handleDeselectAll,
      refreshRecruitmentNegotiation,
      rejectNegotiationRooms,
      setModal,
    ]
  );

  const rejectRaisonModal = useCallback(
    (ids: string[]) => async () => {
      setModal({
        title: t('modal.titles.rejectOffer', { count: checkedRooms.length }),
        content: (
          <RejectionReasonModal
            onCancel={() => setModal(null)}
            onConfirm={async (data) => {
              handleConfirmRejectNegotiationRooms(
                ids,
                data.rejectionReason,
                data.otherReason!
              );
            }}
          />
        ),
      });
    },
    [checkedRooms.length, handleConfirmRejectNegotiationRooms, setModal, t]
  );

  const handleOnRejectNegotiationRooms = useCallback(
    (roomIds: string[]) => {
      setModal({
        title: t('actions.rejectOffersTitle', { count: checkedRooms.length }),
        content: (
          <ConfirmationModal
            content={
              <Stack>
                <p className="whitespace-pre-line">
                  <Trans
                    i18nKey="actions.rejectOffersMessage"
                    t={t}
                    values={{
                      count: checkedRooms.length,
                      agencies: getAccountNameList(roomIds),
                    }}
                  />
                </p>
              </Stack>
            }
            onCancel={() => setModal(null)}
            onConfirm={rejectRaisonModal(roomIds)}
          />
        ),
        maxWidth: '2xl',
      });
    },
    [checkedRooms.length, getAccountNameList, rejectRaisonModal, setModal, t]
  );

  useEffect(() => {
    setIsBottomMenuOpen(isDefined(acceptedRoom) || checkedRooms.length >= 1);
  }, [acceptedRoom, checkedRooms, setIsBottomMenuOpen]);

  return (
    <div
      className={cn(
        'fixed bottom-s-0 right-s-0 bg-base-100 shadow-lg px-s-32 py-s-16 w-full min-h-[4.5rem] hidden',
        {
          block: checkedRooms.length >= 1 || acceptedRoom,
        },
        paddingLeft
      )}
    >
      {acceptedRoom ? (
        <div className="flex gap-s-16 justify-end">
          <ButtonLink
            size="small"
            className="py-s-0"
            icon={<i className="ri-shake-hands-line text-16 font-normal" />}
            to={agreementPath}
            primary
          >
            {t('preAgreement')}
          </ButtonLink>

          <ButtonLink
            size="small"
            className="py-s-0"
            icon={<i className="ri-file-text-line text-16 font-normal" />}
            to={getOfferDetailsPath(acceptedRoom.id)}
          >
            {t('offerDetails')}
          </ButtonLink>
        </div>
      ) : (
        <div className="flex flex-col lg:flex-row justify-between">
          <div className="flex items-center gap-s-16">
            <span>{t('offersCount', { count: checkedRooms.length })}</span>
            {!isComparing && (
              <Button size="small" ghost onClick={handleDeselectAll}>
                {t('deselectOffer', { count: checkedRooms.length })}
              </Button>
            )}
          </div>

          <div className="flex flex-col md:flex-row gap-s-16">
            {/* IF only one room is selected AND not rejected yet -> Show the create agreement button */}
            <Button
              size="small"
              primary
              icon={
                <i className="ri-shake-hands-line mr-s-4 text-16 font-normal" />
              }
              className={
                checkedRooms.length === 1 &&
                rooms?.some(
                  (room) =>
                    room.id === checkedRooms[0] &&
                    room.status !== RecruitmentNegotiationRoomStatus.Rejected
                )
                  ? 'py-s-0'
                  : 'hidden'
              }
              onClick={() => handleOnCreateAgreement(checkedRooms[0])}
            >
              {t('createAgreement')}
            </Button>

            {/* IF we only have ONE room checked -> Show the room details button */}
            <ButtonLink
              size="small"
              icon={<i className="ri-file-text-line text-16 font-normal" />}
              className={
                checkedRooms.length === 1
                  ? 'flex items-center py-s-0'
                  : 'hidden'
              }
              to={getOfferDetailsPath(checkedRooms[0])}
              from={location.pathname}
            >
              {t('offerDetails')}
            </ButtonLink>

            {/* IF none of the checked rooms has been rejected yet -> Show the reject offer button */}
            <Button
              size="small"
              icon={
                <i className="ri-prohibited-line mr-s-4 text-16 font-normal" />
              }
              className={
                rooms
                  ?.filter((r) => checkedRooms.includes(r.id))
                  .every(
                    (r) =>
                      r.status !== RecruitmentNegotiationRoomStatus.Rejected
                  )
                  ? 'py-s-0'
                  : 'hidden'
              }
              onClick={() => handleOnRejectNegotiationRooms(checkedRooms)}
            >
              {t('declineOffers')}
            </Button>

            {/* IF there's more than one room checked -> Show the comparison button */}
            {/* IF we are currently isComparing rooms -> Change the text to cancel selection */}
            <Button
              className={checkedRooms.length > 1 ? 'py-s-0' : 'hidden'}
              size="small"
              onClick={() => setIsComparing(!isComparing)}
            >
              {t(`${isComparing ? 'cancelSelection' : 'compareOffers'}`)}
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ActionMenu;
