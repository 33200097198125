import { Trans, useTranslation } from 'react-i18next';

import { Button, Modal } from '@application/components';
import { Table } from '@application/components/table';
import { CandidateOpportunity } from '@domain/graphql.types';

import { useCandidateOpportunitiesMatchingRequestList } from '../hooks/useCandidateOpportunitiesMatchingRequestList';

export type CandidateOpportunitiesMatchingRequestModalProps = {
  requestMatchingCandidateOpportunities: CandidateOpportunity[];
  onClose: () => void;
};

const CandidateOpportunitiesMatchingRequestModal = ({
  requestMatchingCandidateOpportunities,
  onClose,
}: CandidateOpportunitiesMatchingRequestModalProps) => {
  const { t: tGlobal } = useTranslation();
  const { t } = useTranslation('candidates', { keyPrefix: 'modal' });

  const { columns, candidateOpportunitiesMatchingRequest, count } =
    useCandidateOpportunitiesMatchingRequestList(
      requestMatchingCandidateOpportunities
    );

  return (
    <div>
      <p className="whitespace-pre-line mb-s-16">
        <Trans i18nKey="contents.matchingCandidatModalContent" t={t} />
      </p>
      <Modal.CloseBtn onClick={onClose} />
      <Table
        data={candidateOpportunitiesMatchingRequest}
        columns={columns}
        count={count}
        isRowColorChangeOnHover
      />

      <Modal.Action>
        <Button primary onClick={onClose}>
          {tGlobal('button.confirm')}
        </Button>
      </Modal.Action>
    </div>
  );
};

export default CandidateOpportunitiesMatchingRequestModal;
