import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'urql';

import { AlertContext } from '@application/context';
import MutationUpdateCandidatePrivateNotes from '@infrastructure/graphql/offer/candidate/update-candidate-private-notes.mutation';

import { toViewModel } from '../view-models/update-candidate-private-notes.vm';

const useUpdateCandidatePrivateNotes = () => {
  const [{ data, fetching, error }, updateCandidatePrivateNotes] = useMutation(
    MutationUpdateCandidatePrivateNotes
  );

  const { setAlertContent } = useContext(AlertContext);
  const { t } = useTranslation('candidates', { keyPrefix: 'messages' });

  const viewModel = toViewModel({
    candidate: {
      data,
      fetching,
      error,
    },
  });

  useEffect(() => {
    if (!error) return;

    setAlertContent({
      action: t('updateCandidatePrivateNotesError'),
      modifier: 'alert-error',
    });
  }, [error, setAlertContent, t]);

  useEffect(() => {
    if (data?.updateCandidatePrivateNotes?.candidate) {
      setAlertContent({
        action: t('updateCandidatePrivateNotesSuccess'),
        modifier: 'alert-success',
      });
    }
  }, [data?.updateCandidatePrivateNotes?.candidate, setAlertContent, t]);

  return {
    viewModel,
    updateCandidatePrivateNotes,
  };
};

export default useUpdateCandidatePrivateNotes;
