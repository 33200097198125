import { useCallback, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ConfirmationModal } from '@application/components';
import { ContextualMenu } from '@application/components/contextual-menu';
import { ModalContext } from '@application/context';
import { OfferCandidateStatusTypeCode } from '@domain/graphql.types';

import CandidateHireModal from '../hired/CandidateHiredModal';
import CandidateRejectionReasonModal from '../rejection-reason/CandidateRejectionReasonModal';

type CandidateActionMenuProps = {
  candidateId: string;
  status: OfferCandidateStatusTypeCode;
  onHire: (candidateId: string, closeRequest: boolean) => () => Promise<void>;
  onInterview: (candidateId: string) => () => Promise<void>;
  onReject: (
    candidateId: string,
    rejectionReason: string,
    rejectionExplanation: string
  ) => () => Promise<void>;
};

export const CandidateActionMenu = ({
  candidateId,
  status,
  onHire,
  onInterview,
  onReject,
}: CandidateActionMenuProps) => {
  const { t } = useTranslation('candidates');
  const { setModal } = useContext(ModalContext);

  const handleRejectCandidate = useCallback(
    async (rejectionReason: string, rejectionExplanation: string) => {
      setModal(null);
      await onReject(candidateId, rejectionReason, rejectionExplanation)();
    },
    [candidateId, onReject, setModal]
  );

  const openRejectionReasonModal = useCallback(() => {
    setModal({
      title: t('modal.titles.rejectionReason'),
      maxWidth: '2xl',
      content: (
        <CandidateRejectionReasonModal
          onCancel={() => setModal(null)}
          onConfirm={(rejectedCandidateData) => {
            handleRejectCandidate(
              rejectedCandidateData.rejectionReason,
              rejectedCandidateData.otherReason!
            );
          }}
        />
      ),
    });
  }, [handleRejectCandidate, setModal, t]);

  const handleInterviewCandidate = useCallback(async () => {
    setModal(null);
    await onInterview(candidateId)();
  }, [candidateId, onInterview, setModal]);

  const openInterviewModal = useCallback(() => {
    setModal({
      title: t('modal.titles.interview'),
      maxWidth: 'xl',
      content: (
        <ConfirmationModal
          content={t('modal.contents.interviewConfirmation')}
          onCancel={() => setModal(null)}
          onConfirm={handleInterviewCandidate}
        />
      ),
    });
  }, [handleInterviewCandidate, setModal, t]);

  const handleHireCandidate = useCallback(
    async (closeRequest: boolean) => {
      setModal(null);
      await onHire(candidateId, closeRequest)();
    },
    [candidateId, onHire, setModal]
  );

  const openHireConfirmationModal = useCallback(() => {
    setModal({
      title: t('modal.titles.hireConfirmation'),
      maxWidth: '2xl',
      content: (
        <CandidateHireModal
          onCancel={() => setModal(null)}
          onConfirm={handleHireCandidate}
        />
      ),
    });
  }, [handleHireCandidate, setModal, t]);

  const getAvailableActions = useMemo(() => {
    const interviewAction = {
      label: t('button.interview'),
      onClick: openInterviewModal,
    };

    const hireAction = {
      label: t('button.hire'),
      onClick: openHireConfirmationModal,
    };

    const rejectAction = {
      label: t('button.reject'),
      onClick: openRejectionReasonModal,
    };

    switch (status) {
      case OfferCandidateStatusTypeCode.Rejected:
        return [interviewAction, hireAction];

      case OfferCandidateStatusTypeCode.Hired:
        return [interviewAction, rejectAction];

      case OfferCandidateStatusTypeCode.Interview:
        return [hireAction, rejectAction];

      default:
        return [interviewAction, hireAction, rejectAction];
    }
  }, [
    openHireConfirmationModal,
    openInterviewModal,
    openRejectionReasonModal,
    status,
    t,
  ]);

  return (
    getAvailableActions.length > 0 && (
      <ContextualMenu
        buttonClassName="justify-center"
        menuClassName="z-10"
        actions={getAvailableActions}
      />
    )
  );
};
