import React, { ForwardedRef, ReactNode } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { cn } from '@utils/lib-utils';

type LinkProps = {
  to: string;
  from?: string;
  children: ReactNode;
  className?: string;
  role?: string;
  newTab?: boolean;
  onClick?: () => void;
};

type Ref = HTMLAnchorElement;

const Link = React.forwardRef<Ref, LinkProps>(
  (
    {
      className: customClassName,
      children,
      to,
      from,
      role,
      newTab = false,
      onClick,
      ...rest
    }: LinkProps,
    ref: ForwardedRef<Ref>
  ) => {
    const className = cn('text-16 font-semibold underline', customClassName);
    return (
      <RouterLink
        ref={ref}
        to={to}
        target={newTab ? '_blank' : undefined}
        className={className}
        role={role}
        state={{ from }}
        {...rest}
        onClick={onClick}
      >
        {children}
      </RouterLink>
    );
  }
);

export default Link;
