import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { PageLoader } from '@application/components';
import { RootPrivatePage } from '@application/enums/pagesUrl';

import { ChatRoom } from './components';
import { ChatListItemProps } from './components/ChatListItem';
import { ChatContent, ChatListProps } from './components/ChatRoom';
import {
  useCandidateRequestChatRoomMessagesList,
  useCandidateRequestChatRoomsList,
  useCreateCandidateRequestChatRoomMessage,
} from './hooks';

type CandidateRequestChatContentProps = {
  candidateRequestId: string;
  accountId: string;
  title: string;
  subtitle: string;
};

const CandidateRequestChatContent = ({
  candidateRequestId,
  accountId,
  title,
  subtitle,
}: CandidateRequestChatContentProps) => {
  const [selectedChatRoomId, setSelectedChatRoomId] = useState<string>('');
  const { t } = useTranslation('chatRoom');

  const { createCandidateRequestChatRoomMessage } =
    useCreateCandidateRequestChatRoomMessage();

  const {
    candidateRequestChatRooms,
    isLoading: candidateRequestChatRoomsIsLoading,
    refetchCandidateRequestChatRooms,
  } = useCandidateRequestChatRoomsList({
    candidateRequestId,
    agencyAccountId: accountId,
  });

  const {
    candidateRequestChatRoomMessages,
    isLoading: candidateRequestChatRoomMessagesIsLoading,
    refetchCandidateRequestChatRoomMessages,
  } = useCandidateRequestChatRoomMessagesList({
    candidateRequestChatRoomId: selectedChatRoomId,
  });

  const [
    chatListUnderNegotiation,
    chatListNotUnderNegotiation,
    chatListNoOffer,
    chatListNoOfferNoMessage,
  ]: [
    ChatListProps | undefined,
    ChatListProps | undefined,
    ChatListProps | undefined,
    ChatListProps | undefined,
  ] = useMemo(() => {
    const underNegotiation: ChatListProps = {
      groupeTitle: t('labels.candidateUnderNegotiation'),
      chatRoomList: [],
    };
    const notUnderNegotiation: ChatListProps = {
      groupeTitle: t('labels.candidateNotUnderNegotiation'),
      chatRoomList: [],
    };
    const noOffer: ChatListProps = {
      groupeTitle: t('labels.candidateNoOffer'),
      chatRoomList: [],
    };
    const noOfferNoMessage: ChatListProps = {
      groupeTitle: t('labels.candidateNoOfferNoMessage'),
      chatRoomList: [],
    };

    candidateRequestChatRooms?.forEach((chatRoom) => {
      const chatItem: ChatListItemProps = {
        selected: selectedChatRoomId === chatRoom.id,
        rank: chatRoom.candidateOfferData?.rank ?? undefined,
        title: chatRoom.enterpriseName ?? '',
        preview: chatRoom.enterpriseLastMessage?.message ?? '',
        date: chatRoom.enterpriseLastMessage?.sentAt,
        newMessageCount: 0, // TODO for v2 get the count of unread message by the last read message date
        onClick: () => setSelectedChatRoomId(chatRoom.id),
      };

      if (chatRoom.candidateOfferData?.isUnderNegotiation) {
        underNegotiation.chatRoomList.push(chatItem);
      } else if (chatRoom.candidateOfferData?.hasMadeOffer) {
        notUnderNegotiation.chatRoomList.push(chatItem);
      } else if (chatRoom.enterpriseLastMessage) {
        noOffer.chatRoomList.push(chatItem);
      } else {
        noOfferNoMessage.chatRoomList.push(chatItem);
      }
    });

    return [
      underNegotiation.chatRoomList.length ? underNegotiation : undefined,
      notUnderNegotiation.chatRoomList.length ? notUnderNegotiation : undefined,
      noOffer.chatRoomList.length ? noOffer : undefined,
      noOfferNoMessage.chatRoomList.length ? noOfferNoMessage : undefined,
    ];
  }, [t, candidateRequestChatRooms, selectedChatRoomId]);

  const selectedChatRoom = useMemo(
    () =>
      candidateRequestChatRooms?.find(
        (chatRoom) => chatRoom.id === selectedChatRoomId
      ),
    [candidateRequestChatRooms, selectedChatRoomId]
  );

  const chatContent: ChatContent = useMemo(
    () => ({
      title: selectedChatRoom?.enterpriseName ?? '',
      messageList: candidateRequestChatRoomMessages?.map((message) => ({
        position:
          message.accountId === accountId
            ? ('right' as const)
            : ('left' as const),
        date: message.sentAt,
        viewed: true,
        content: message.message,
      })),
    }),
    [
      candidateRequestChatRoomMessages,
      accountId,
      selectedChatRoom?.enterpriseName,
    ]
  );

  const handleSendMessage = useCallback(
    async (message: string) => {
      await createCandidateRequestChatRoomMessage({
        input: {
          candidateRequestChatRoomId: selectedChatRoomId,
          message,
        },
      });

      if (candidateRequestChatRoomMessages.length < 1) {
        refetchCandidateRequestChatRoomMessages();
      }
    },
    [
      createCandidateRequestChatRoomMessage,
      candidateRequestChatRoomMessages.length,
      refetchCandidateRequestChatRoomMessages,
      selectedChatRoomId,
    ]
  );

  const refetchChatRoomsAndMessages = useCallback(async () => {
    refetchCandidateRequestChatRooms();
    refetchCandidateRequestChatRoomMessages();
  }, [
    refetchCandidateRequestChatRooms,
    refetchCandidateRequestChatRoomMessages,
  ]);

  useEffect(() => {
    if (candidateRequestChatRooms?.length && !selectedChatRoomId) {
      setSelectedChatRoomId(candidateRequestChatRooms[0].id);
    }
  }, [candidateRequestChatRooms, selectedChatRoomId]);

  return candidateRequestChatRoomsIsLoading ? (
    <PageLoader />
  ) : (
    <ChatRoom
      isLoading={candidateRequestChatRoomMessagesIsLoading}
      title={title}
      subtitle={subtitle}
      chatListUnderNegotiation={chatListUnderNegotiation}
      chatListNotUnderNegotiation={chatListNotUnderNegotiation}
      chatListNoOffer={chatListNoOffer}
      chatListNoOfferNoMessage={chatListNoOfferNoMessage}
      chatContent={chatContent}
      backTo={RootPrivatePage.CANDIDATE_REQUESTS}
      onSendMessage={handleSendMessage}
      refetchMessages={refetchChatRoomsAndMessages}
    />
  );
};

export default CandidateRequestChatContent;
