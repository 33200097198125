import {
  ColumnDef,
  createColumnHelper,
  OnChangeFn,
  PaginationState,
  SortingState,
} from '@tanstack/react-table';
import { TFunction } from 'i18next';
import { DateTime } from 'luxon';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useLocation } from 'react-router';
import { Link } from 'react-router-dom';

import { Badge, BadgeList, NumericBadge } from '@application/components/badge';
import { ButtonLinkCounter } from '@application/components/buttons';
import { ButtonLink } from '@application/components/links';
import { ConfirmationModal } from '@application/components/modal';
import { PictureBadges } from '@application/components/picture-badges';
import { CandidateRequestTypeBadge } from '@application/components/request-type-badges';
import { ModalContext } from '@application/context';
import { PrivatePage, RootPrivatePage } from '@application/enums/pagesUrl';
import { usePagination } from '@application/hooks';
import { mapDescriptions } from '@application/utils';
import {
  CandidateRequest,
  CandidateRequestsFilters,
  CandidateRequestsSortBy,
  CandidateRequestsSortDirective,
  OperationTerritory,
  RequestStatusTypeCode,
  SortDirection,
} from '@domain/graphql.types';
import { flattenEdges } from '@utils/data-utils';
import { getDiffFromNow, isOverDeadline } from '@utils/date-utils';
import { extractLanguage, getLocalizedDescription } from '@utils/i18n-utils';
import { getRequestStatusClassNames } from '@utils/styles-utils';

import { ActionMenu } from '../components';
import useDeleteCandidateRequest from './useDeleteCandidateRequest';
import useDuplicateCandidateRequest from './useDuplicateCandidateRequest';
import useGetCandidateRequests from './useGetCandidateRequests';

const columnIds = {
  avatar: 'avatar',
  candidateName: 'candidateName',
  jobSpecialtyCode: 'jobSpecialtyCode',
  candidateOffersCount: 'candidateOffersCount',
  type: 'type',
  status: 'status',
  operationTerritories: 'operationTerritories',
  receivingOfferDeadline: 'receivingOfferDeadline',
  link: 'link',
  actions: 'actions',
} as const;

const columnHelper = createColumnHelper<CandidateRequest>();

const getFormattedDate = (
  date: string,
  tGlobal: TFunction<'translation', undefined>
) => {
  const result = getDiffFromNow(date);
  if (getDiffFromNow(date)?.length === 0) {
    return tGlobal(`date.today`);
  }
  return result
    ?.map((d) =>
      tGlobal(`date.options.${d.unit}`, {
        count: d.value,
      })
    )
    .join(' ');
};

const getColumns = (
  language: string,
  t: TFunction<'translation', undefined>,
  tGlobal: TFunction<'translation', undefined>,
  onDelete: (requestId: string) => () => void,
  onDuplicate: (requestId: string) => () => Promise<void>,
  hash: string
) => {
  const columns: ColumnDef<CandidateRequest, any>[] = [
    columnHelper.accessor((row) => row.user.avatarUrl, {
      id: columnIds.avatar,
      cell: ({ row }) => (
        <PictureBadges
          pictures={[
            {
              name: row.original.user.name ?? undefined,
              url: row.original.user.avatarUrl,
            },
          ]}
          rounded="rounded-md"
          tooltipPosition="right"
        />
      ),
      header: () => '',
      size: 40,
      minSize: 40,
      maxSize: 40,
      enableSorting: false,
    }),
    columnHelper.accessor(
      (row) => [row.firstName, row.lastName].filter(Boolean).join(' '),
      {
        id: columnIds.candidateName,
        cell: (info) => (
          <Link
            to={generatePath(PrivatePage.CANDIDATE_REQUEST_DETAILS, {
              id: info.row.original.id,
            })}
            className="hover:underline"
          >
            <span>{info.getValue() || '-'}</span>
          </Link>
        ),
        header: () => t('list.column.candidateName'),
        size: 200,
        minSize: 185,
        maxSize: undefined,
        sortDescFirst: true,
      }
    ),
    columnHelper.accessor(
      (row) =>
        getLocalizedDescription(row.jobSpecialty?.descriptions, language),
      {
        id: columnIds.jobSpecialtyCode,
        cell: (info) => <span>{info.getValue() || '-'}</span>,
        header: () => t('list.column.job'),
        size: 256,
        minSize: 256,
        maxSize: undefined,
        sortDescFirst: true,
      }
    ),
    columnHelper.accessor((row) => row, {
      id: columnIds.candidateOffersCount,
      cell: ({ row }) => (
        <NumericBadge
          value={row.original.candidateOffersCount}
          to={
            row.original.candidateOffersCount > 0
              ? generatePath(PrivatePage.CANDIDATE_OFFER_COMPARISON, {
                  id: row.original.id,
                })
              : undefined
          }
        />
      ),
      header: () => t('list.column.offers'),
      size: 88,
      minSize: 88,
      maxSize: 88,
      enableSorting: false,
    }),
    columnHelper.accessor((row) => row.type, {
      id: columnIds.type,
      cell: (info) => <CandidateRequestTypeBadge type={info.getValue()} />,
      header: () => t('list.column.type'),
      size: 88,
      minSize: 88,
      maxSize: 88,
      sortDescFirst: true,
    }),
    columnHelper.accessor((row) => row.status, {
      id: columnIds.status,
      cell: (info) => (
        <Badge
          value={t(`list.hashPath.${info.getValue().toLowerCase()}`)}
          placeholder="-"
          className={getRequestStatusClassNames(info.getValue())}
        />
      ),
      header: () => t('list.column.status'),
      size: 128,
      minSize: 128,
      maxSize: 128,
      sortDescFirst: true,
    }),
    columnHelper.accessor((row) => row.operationTerritories, {
      id: columnIds.operationTerritories,
      cell: (info) => {
        const operationTerritories = mapDescriptions(
          info
            .getValue()
            ?.map((territory: OperationTerritory) => territory.descriptions) ||
            [],
          language
        );

        return <BadgeList values={operationTerritories} />;
      },
      header: () => t('list.column.operationTerritories'),
      size: 272,
      minSize: 272,
      maxSize: undefined,
      enableSorting: false,
    }),
    columnHelper.accessor((row) => row.receivingOfferDeadline, {
      id: columnIds.receivingOfferDeadline,
      cell: (info) => {
        if (!info.getValue()) return '-';
        return (
          <span>
            {isOverDeadline(
              info.getValue().toString(),
              DateTime.now().toString()
            )
              ? tGlobal('date.isOverDeadline')
              : getFormattedDate(info.getValue(), tGlobal)}
          </span>
        );
      },
      header: () => t('list.column.deadline'),
      size: 130,
      minSize: 130,
      maxSize: undefined,
      sortDescFirst: true,
    }),
    columnHelper.accessor((row) => row, {
      id: columnIds.link,
      cell: ({ row }) =>
        row.original.status === RequestStatusTypeCode.Draft && (
          <ButtonLink
            to={generatePath(PrivatePage.CANDIDATE_REQUEST_EDIT, {
              id: row.original.id,
            })}
            size="x-small"
            className="whitespace-nowrap py-s-0 animate-none"
          >
            {t('list.actions.resumeCreation')}
          </ButtonLink>
        ),
      header: () => null,
      size: 150,
      minSize: 150,
      maxSize: 150,
      sortDescFirst: true,
    }),
    columnHelper.accessor((row) => row, {
      id: columnIds.actions,
      cell: ({ row }) => (
        <div className="flex items-center justify-end gap-s-8">
          {row.original.hasEnterpriseToChat && (
            <ButtonLinkCounter
              iconName="ri-question-answer-line"
              to={generatePath(PrivatePage.CANDIDATE_REQUEST_CHAT, {
                id: row.original.id,
              })}
              from={generatePath(RootPrivatePage.CANDIDATE_REQUESTS)}
              size="xSmall"
            />
          )}

          <ActionMenu
            id={row.original.id}
            onDuplicate={onDuplicate}
            onDelete={onDelete}
            hideDelete={
              row.original.candidateOffersCount > 0 ||
              row.original.status === RequestStatusTypeCode.Agreement
            }
          />
        </div>
      ),
      header: () => null,
      size: 100,
      minSize: 100,
      maxSize: 100,
      sortDescFirst: true,
    }),
  ];

  return columns.filter((column) => {
    if (hash === '#draft') {
      const draftColumns = [
        columnIds.candidateName,
        columnIds.jobSpecialtyCode,
        columnIds.type,
        columnIds.operationTerritories,
        columnIds.link,
        columnIds.actions,
      ];
      return !!draftColumns.some((id) => id === column.id);
    }
    return true;
  });
};

const stringSortToEnum = (
  by: string,
  language: string
): CandidateRequestsSortBy => {
  const extractedLanguage = extractLanguage(language);

  switch (by) {
    case columnIds.receivingOfferDeadline:
      return CandidateRequestsSortBy.Deadline;
    case columnIds.candidateName:
      return CandidateRequestsSortBy.Name;
    case columnIds.jobSpecialtyCode:
      return extractedLanguage === 'en'
        ? CandidateRequestsSortBy.JobSpecialtyEn
        : CandidateRequestsSortBy.JobSpecialtyFr;
    default:
      return CandidateRequestsSortBy.Name;
  }
};

const mapSorting = (
  sorting: SortingState,
  language: string
): CandidateRequestsSortDirective[] =>
  sorting.map((s) => ({
    direction: s.desc ? SortDirection.Desc : SortDirection.Asc,
    by: stringSortToEnum(s.id, language),
  }));

type UseCandidateRequestsListProps = {
  columnIds: typeof columnIds;
  columns: ColumnDef<CandidateRequest>[];
  count?: number;
  currentPage?: PaginationState;
  onPaginationChange?: OnChangeFn<PaginationState>;
  onPaginationReset?: () => void;
  onSortingChange: OnChangeFn<SortingState>;
  candidateRequests: CandidateRequest[];
  sorting: SortingState;
  isLoading: boolean;
};

export const useCandidateRequestsList = (
  filters?: CandidateRequestsFilters
): UseCandidateRequestsListProps => {
  const [sorting, setSorting] = useState<SortingState>([
    { id: 'createdAt', desc: true },
  ]);
  const { t, i18n } = useTranslation('candidates');
  const { t: tGlobal } = useTranslation();

  const { setModal } = useContext(ModalContext);

  const {
    pagination,
    currentPage,
    handlePaginationChange,
    setCursors,
    resetPagination,
  } = usePagination();

  const {
    viewModel: { data, isLoading },
    refetchCandidateRequests,
  } = useGetCandidateRequests(
    mapSorting(sorting, i18n.language),
    filters,
    pagination
  );

  useEffect(() => {
    setCursors({
      startCursor:
        data?.candidateRequests.page.pageInfo?.startCursor || undefined,
      endCursor: data?.candidateRequests.page.pageInfo?.endCursor || undefined,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.candidateRequests.page.pageInfo]);

  useEffect(() => {
    resetPagination();
  }, [sorting, resetPagination]);

  const { hash } = useLocation();

  useEffect(() => {
    resetPagination();
    refetchCandidateRequests();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hash]);

  const { duplicateCandidateRequest } = useDuplicateCandidateRequest();
  const { deleteCandidateRequest } = useDeleteCandidateRequest();

  const handleDelete = useCallback(
    (candidateRequestId: string) => async () => {
      setModal(null);
      await deleteCandidateRequest({ input: { candidateRequestId } });
      refetchCandidateRequests();
    },
    [deleteCandidateRequest, setModal, refetchCandidateRequests]
  );

  const confirmDelete = useCallback(
    (requestId: string) => () => {
      setModal({
        title: t('modal.titles.deleteRequest'),
        content: (
          <ConfirmationModal
            content={t('modal.contents.confirmDelete')}
            onCancel={() => setModal(null)}
            onConfirm={handleDelete(requestId)}
          />
        ),
      });
    },
    [setModal, t, handleDelete]
  );

  const handleDuplicate = useCallback(
    (candidateRequestId: string) => async () => {
      await duplicateCandidateRequest({ input: { candidateRequestId } });
      refetchCandidateRequests();
    },
    [duplicateCandidateRequest, refetchCandidateRequests]
  );

  const columns = getColumns(
    i18n.language,
    t,
    tGlobal,
    confirmDelete,
    handleDuplicate,
    hash
  );

  return {
    columnIds,
    columns: columns as ColumnDef<CandidateRequest>[],
    count: data?.candidateRequests.pageData?.count,
    currentPage,
    onPaginationChange: handlePaginationChange,
    onPaginationReset: resetPagination,
    onSortingChange: setSorting,
    candidateRequests: flattenEdges(
      data?.candidateRequests.page.edges?.slice() || []
    ),
    sorting,
    isLoading,
  };
};
