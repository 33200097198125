import { yupResolver } from '@hookform/resolvers/yup';
import React, { useMemo } from 'react';
import { Control, Controller, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';

import {
  Modal,
  RadioGroup,
  Stack,
  TextAreaField,
} from '@application/components';
import { Button } from '@application/components/buttons';
import { CandidateRejectionReason } from '@domain/graphql.types';
import { extractLanguage } from '@utils/i18n-utils';

import {
  CANDIDATE_REJECT_REASON_SCHEMA,
  CandidateRejectionReasonFormData,
} from './candidateRejectionReasonSchema';

type CandidateRejectionReasonModalProps = {
  onConfirm: (data: {
    rejectionReason: string;
    otherReason?: string | null;
  }) => void;
  onCancel: () => void;
};

const CandidateRejectionReasonModal = ({
  onConfirm,
  onCancel,
}: CandidateRejectionReasonModalProps) => {
  const { t, i18n } = useTranslation('candidates');
  const { t: tGlobal } = useTranslation();

  const {
    control,
    watch,
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<CandidateRejectionReasonFormData>({
    mode: 'onSubmit',
    resolver: yupResolver(CANDIDATE_REJECT_REASON_SCHEMA),
    defaultValues: {
      rejectionReason: '',
      otherReason: null,
    },
  });

  const otherReason = watch('otherReason');
  const rejectionReason = watch('rejectionReason');

  const rejectionReasonsData = useMemo(() => {
    const reasons = [
      CandidateRejectionReason.PositionFilled,
      CandidateRejectionReason.ProfileMismatch,
      CandidateRejectionReason.InsufficientExperience,
      CandidateRejectionReason.CannotMeetExpectations,
      CandidateRejectionReason.Other,
    ];

    return reasons.map((reason) => ({
      value: reason,
      text: {
        en: tGlobal(`enum.candidateRejectionReason.${reason}`),
        fr: tGlobal(`enum.candidateRejectionReason.${reason}`),
      },
    }));
  }, [tGlobal]);

  const onSubmit = (data: CandidateRejectionReasonFormData) => {
    onConfirm({
      rejectionReason: data.rejectionReason,
      otherReason: data.otherReason,
    });
  };

  return (
    <Stack>
      <p className="whitespace-pre-line">
        <Trans i18nKey="modal.contents.rejectionReason" t={t} />
      </p>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="rejectionReason"
          control={control as Control<CandidateRejectionReasonFormData>}
          render={({ field, fieldState: { error } }) => (
            <RadioGroup
              name={field.name}
              data={rejectionReasonsData}
              radioClassName="min-h-48 max-h-48 justify-center"
              radioParentClassName="grid"
              language={extractLanguage(i18n.language)}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                field.onChange(event.target.value);
              }}
              value={field.value}
              invalid={!!error}
              helperText={error?.message && t(error.message)}
            />
          )}
        />

        {rejectionReason === CandidateRejectionReason.Other && (
          <TextAreaField
            rows={4}
            className="mt-s-8"
            label={t('modal.contents.otherReasonInput')}
            invalid={!!errors.otherReason}
            helperText={
              errors.otherReason?.message &&
              t(errors.otherReason.message, { max: 128 })
            }
            {...register('otherReason')}
          />
        )}

        <Modal.Action className="mt-s-24">
          <Button type="button" onClick={onCancel}>
            {tGlobal('button.cancel')}
          </Button>
          <Button
            type="submit"
            primary
            disabled={
              !rejectionReason ||
              (rejectionReason === CandidateRejectionReason.Other &&
                !otherReason)
            }
          >
            {tGlobal('button.confirm')}
          </Button>
        </Modal.Action>
      </form>
    </Stack>
  );
};

export default CandidateRejectionReasonModal;
