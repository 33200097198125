import { gql } from '@urql/core';

const MutationUpdateCandidateStatusToInterview = gql`
  mutation updateCandidateStatusToInterview(
    $updateCandidateStatusToInterviewInput: UpdateCandidateStatusToInterviewInput!
  ) {
    updateCandidateStatusToInterview(
      updateCandidateStatusToInterviewInput: $updateCandidateStatusToInterviewInput
    ) {
      candidate {
        id
        status
      }
    }
  }
`;

export default MutationUpdateCandidateStatusToInterview;
