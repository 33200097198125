import { CombinedError } from 'urql';

import { extractErrorCodes } from '@application/utils';
import ViewModel from '@application/view-model';
import { CandidateRequestChatRoomMessageResponse } from '@domain/graphql.types';

type ToViewModel = {
  candidateRequestChatRoomMessage: {
    data: CandidateRequestChatRoomMessageResponse | undefined;
    fetching: boolean;
    error: CombinedError | undefined;
  };
};

export const toViewModel = ({
  candidateRequestChatRoomMessage: { data, fetching, error },
}: ToViewModel): ViewModel<
  CandidateRequestChatRoomMessageResponse | undefined
> =>
  new ViewModel<CandidateRequestChatRoomMessageResponse | undefined>({
    isLoading: fetching,
    errorCodes: extractErrorCodes(error),
    data,
  });
