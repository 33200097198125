import { useCallback, useContext, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { generatePath, useNavigate, useParams } from 'react-router';
import { validate as isUUID } from 'uuid';

import {
  ConfirmationModal,
  PageHeader,
  PageLoader,
  RankBadge,
  Stack,
} from '@application/components';
import { Button } from '@application/components/buttons';
import { Link } from '@application/components/links';
import { ModalContext } from '@application/context';
import { PrivatePage, RootPrivatePage } from '@application/enums/pagesUrl';
import { OfferStatusTypeCode } from '@domain/graphql.types';

import { OfferCandidatesList } from '../offer/candidate/list';
import { useRejectOffers } from '../offer/comparison/hooks';
import useAddRoomsToRecruitmentNegotiation from '../offer/comparison/hooks/useAddRoomsToRecruitmentNegotiation';
import useCreateRecruitmentNegotiation from '../offer/comparison/hooks/useCreateRecruitmentNegotiation';
import { useGetOffer } from '../offer/hooks';
import useSeenAtOffer from '../offer/hooks/useOfferSeenAt';
import { useGetRequest } from './hooks';
import { ConditionsSummary } from './offer-summary';
import SalesPitch from './offer-summary/salesPitch/SalesPitch';
import RejectionReasonModal from './rejection-reason/RejectionReasonModal';

const OfferDetailsPage = () => {
  const { t } = useTranslation('requests');

  const { id = '', offerId = '' } = useParams();
  const navigate = useNavigate();

  const { setModal } = useContext(ModalContext);

  useEffect(() => {
    if (!(id && isUUID(id)) || !(offerId && isUUID(offerId))) {
      navigate(RootPrivatePage.NOT_FOUND, { replace: true });
    }
  }, [id, offerId, navigate]);

  const {
    viewModel: { data, isLoading },
    refetchOffer,
  } = useGetOffer(offerId);

  useSeenAtOffer(offerId);

  const {
    viewModel: { data: request },
  } = useGetRequest(id);

  const { createRecruitmentNegotiation } = useCreateRecruitmentNegotiation();

  const { addRoomsToRecruitmentNegotiation } =
    useAddRoomsToRecruitmentNegotiation();

  const { rejectOffers } = useRejectOffers();

  const confirmInviteNegoRoom = useCallback(async () => {
    if (data && request) {
      const response = await createRecruitmentNegotiation({
        input: {
          offerIds: [data?.id],
          requestId: request && request.id,
        },
      });
      navigate(
        generatePath(PrivatePage.RECRUITMENT_NEGOTIATION_DETAILS, {
          id:
            response?.data?.recruitmentNegotiationCreate.negotiation.id || '#',
        })
      );
    }
  }, [createRecruitmentNegotiation, data, navigate, request]);

  const confirmInviteExistingNegoRoom = useCallback(async () => {
    // Should only be called when there is already an existing negotiation and we only select offers which are not in the negotiation yet.
    if (request && request.recruitmentNegotiation?.id && data) {
      await addRoomsToRecruitmentNegotiation({
        input: {
          offerIds: [data?.id],
          recruitmentNegotiationId: request.recruitmentNegotiation?.id,
        },
      });
      navigate(
        generatePath(PrivatePage.RECRUITMENT_NEGOTIATION_DETAILS, {
          id: request.recruitmentNegotiation?.id || '#',
        })
      );
    }
  }, [addRoomsToRecruitmentNegotiation, data, navigate, request]);

  const handleClickInviteNegoRoom = useCallback(() => {
    if (!request?.recruitmentNegotiation) {
      confirmInviteNegoRoom();
    } else {
      confirmInviteExistingNegoRoom();
    }
  }, [
    confirmInviteExistingNegoRoom,
    confirmInviteNegoRoom,
    request?.recruitmentNegotiation,
  ]);

  const handleRejectOffer = useCallback(
    async (rejectionReason: string, rejectionExplanation: string) => {
      setModal(null);

      const result = await rejectOffers({
        input: {
          ids: [offerId],
          rejectionReason,
          rejectionExplanation,
        },
      });

      if (result.data?.offersReject.offers) {
        refetchOffer();
      }
    },
    [setModal, rejectOffers, offerId, refetchOffer]
  );

  const rejectRaisonModal = useCallback(() => {
    setModal({
      title: t('modal.titles.reasonRejectOffer'),
      content: (
        <RejectionReasonModal
          onCancel={() => setModal(null)}
          onConfirm={(rejectedOfferData) => {
            handleRejectOffer(
              rejectedOfferData.rejectionReason,
              rejectedOfferData.otherReason!
            );
          }}
        />
      ),
    });
  }, [handleRejectOffer, setModal, t]);

  const confirmOfferRejection = useCallback(() => {
    setModal({
      title: t('modal.titles.rejectOffer'),
      content: (
        <ConfirmationModal
          content={
            <Stack>
              <span className="whitespace-pre-line">
                <Trans
                  i18nKey="modal.contents.confirmRejectOffer"
                  t={t}
                  values={{
                    name: data?.account.name,
                  }}
                />
              </span>
            </Stack>
          }
          onCancel={() => setModal(null)}
          onConfirm={rejectRaisonModal}
        />
      ),
    });
  }, [setModal, t, data?.account.name, rejectRaisonModal]);

  return isLoading ? (
    <PageLoader />
  ) : (
    <>
      <PageHeader
        titleAs="h1"
        title={
          data?.accountId ? (
            <span className="h3">
              <span>{t('title.offerDetailsWithoutName')}&nbsp;</span>
              <Link
                className="text-24"
                to={generatePath(PrivatePage.ORGANIZATION_PUBLIC_PROFILE, {
                  id: data?.accountId,
                })}
              >
                {data?.account?.name}
              </Link>
            </span>
          ) : (
            <span className="h3">
              {t('title.offerDetails', { accountName: data?.account?.name })}
            </span>
          )
        }
        complement={
          <RankBadge
            rank={Number(data?.rank)}
            className="mr-s-16 min-w-[2rem] text-center"
          />
        }
        backTo={generatePath(PrivatePage.OFFER_COMPARISON, { id })}
      >
        {/* If the offer has no nego room AND the offer is not rejected yet -> Show the reject button */}
        {/* TODO: Showing the status here when the offer is declined would be great instead of nothing */}
        {!data?.negotiationRoomId &&
          data?.status !== OfferStatusTypeCode.Rejected &&
          data?.status !== OfferStatusTypeCode.Accepted && (
            <Button
              icon={<i className="ri-prohibited-line" />}
              onClick={confirmOfferRejection}
            >
              {t('button.reject')}
            </Button>
          )}
      </PageHeader>

      <OfferCandidatesList
        offerId={offerId}
        noCandidatesExplaination={data?.noCandidatesExplaination || undefined}
        requestId={request?.id}
        disabledAction={
          data?.status === OfferStatusTypeCode.Accepted ||
          data?.status === OfferStatusTypeCode.Rejected
        }
      />

      <SalesPitch salesPitch={data?.salesPitch || undefined} />

      <ConditionsSummary
        data={data}
        request={request}
        onClickInviteNegoRoom={handleClickInviteNegoRoom}
      />
    </>
  );
};

export default OfferDetailsPage;
