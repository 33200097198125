// CandidatePrivateNotesModal.tsx
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Modal, Stack, TextAreaField } from '@application/components';
import { Button } from '@application/components/buttons';

type FormValues = {
  privateNotes: string;
};

type CandidatePrivateNotesModalProps = {
  initialNotes: string;
  onConfirm: (notes: string) => void;
  onCancel: () => void;
};

const CandidatePrivateNotesModal = ({
  initialNotes,
  onConfirm,
  onCancel,
}: CandidatePrivateNotesModalProps) => {
  const { t: tGlobal } = useTranslation();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: {
      privateNotes: initialNotes,
    },
  });

  const onSubmit = (data: FormValues) => {
    onConfirm(data.privateNotes);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack>
        <Controller
          name="privateNotes"
          control={control}
          render={({ field: { onChange, name, value } }) => (
            <TextAreaField
              name={name}
              value={value || ''}
              rows={16}
              maxChar={1024}
              onChange={onChange}
              invalid={!!errors.privateNotes}
              helperText={
                errors.privateNotes?.message &&
                tGlobal(errors.privateNotes?.message, { max: '1024' })
              }
            />
          )}
        />
        <Modal.Action className="mt-s-24">
          <Button type="button" onClick={onCancel}>
            {tGlobal('button.cancel')}
          </Button>
          <Button type="submit" primary>
            {tGlobal('button.save')}
          </Button>
        </Modal.Action>
      </Stack>
    </form>
  );
};

export default CandidatePrivateNotesModal;
