import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { AlertContext } from '@application/context';
import { useCandidateRequestChatRoomMessageCreate } from '@domain/chat';

import { toViewModel } from '../../view-models/message/create-candidate-request-chat-room-message.vm';

const useCreateCandidateRequestChatRoomMessage = () => {
  const { data, fetching, error, createCandidateRequestChatRoomMessage } =
    useCandidateRequestChatRoomMessageCreate();

  const { t } = useTranslation('chatRoom', { keyPrefix: 'messages' });

  const { setAlertContent } = useContext(AlertContext);

  const viewModel = toViewModel({
    candidateRequestChatRoomMessage: { data, fetching, error },
  });

  const { errorCodes } = viewModel;

  useEffect(() => {
    if (errorCodes.length === 0) {
      return;
    }

    setAlertContent({
      action: t('createCandidateRequestChatRoomMessageError'),
      modifier: 'alert-error',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorCodes.length]);

  return { createCandidateRequestChatRoomMessage, viewModel };
};

export default useCreateCandidateRequestChatRoomMessage;
