import { useTranslation } from 'react-i18next';
import { UseQueryExecute } from 'urql';

import { RecruitmentNegotiationRoom } from '@domain/graphql.types';

import TableBody from './TableBody';
import TableHeader from './TableHeader';

type NegotiatingOfferTableProps = {
  room: RecruitmentNegotiationRoom;
  refetchRoom: UseQueryExecute;
};

const NegotiatingOfferTable = ({
  refetchRoom,
  room,
}: NegotiatingOfferTableProps) => {
  const { t } = useTranslation('recruitment', {
    keyPrefix: 'negotiation',
  });

  return (
    <table className="w-full bg-base-100">
      <caption className="sr-only">{t('ongoingNegotiations')}</caption>
      <TableHeader room={room} />

      <TableBody room={room} refetchRoom={refetchRoom} />
    </table>
  );
};

export default NegotiatingOfferTable;
