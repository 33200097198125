import { ChangeEvent, useCallback } from 'react';
import {
  Control,
  Controller,
  UseFormSetValue,
  UseFormTrigger,
  UseFormWatch,
} from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';

import { RadioGroup, SectionGridRow } from '@application/components';
import { BOOLEAN_VALUES } from '@application/constants';
import { RequestTypeCode } from '@domain/graphql.types';
import { extractLanguage } from '@utils/i18n-utils';

import { Section } from '../../components';
import { RequestFormFields } from '../../schema';

type HRNeedsFieldsProps = {
  control: Control<RequestFormFields, any>;
  setValue: UseFormSetValue<RequestFormFields>;
  trigger: UseFormTrigger<RequestFormFields>;
  watch: UseFormWatch<RequestFormFields>;
};

const HRNeedsFields = ({
  control,
  setValue,
  trigger,
  watch,
}: HRNeedsFieldsProps) => {
  const { t, i18n } = useTranslation('requests');
  const { t: tGlobal } = useTranslation();

  const types = watch('types') || [];

  const openToInternationalRecruitment = watch(
    'openToInternationalRecruitment'
  );

  const proposeCandidates = watch('conditionCandidateInHandMandatoryValue');

  const conditionCandidateInHandMandatoryData = [
    {
      value: 'false',
      text: {
        fr: t('labels.mustProposeCandidatesFalseLabelLong'),
        en: t('labels.mustProposeCandidatesFalseLabelLong'),
      },
      tooltipMessage: t('labels.mustProposeCandidatesFalseLabelTooltips'),
    },
    {
      value: 'true',
      text: {
        fr: t('labels.mustProposeCandidatesTrueLabelLong'),
        en: t('labels.mustProposeCandidatesTrueLabelLong'),
      },
      tooltipMessage: t('labels.mustProposeCandidatesTrueLabelTooltips'),
    },
  ];

  const openToInternationalRecruitmentOnChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const name = 'openToInternationalRecruitment';

      setValue(name, event.target.value === 'true');
      trigger(name);
    },
    [setValue, trigger]
  );

  return (
    <Section legend={t('subtitles.hrNeed')} padding={0}>
      <SectionGridRow isTopRow className="p-s-24">
        <Controller
          name="types"
          control={control}
          render={({ field: { name, value }, fieldState: { error } }) => (
            <RadioGroup
              className="mx-auto"
              radioParentClassName="flex-col"
              hideLegend
              data={Object.values(RequestTypeCode)
                .filter(
                  (type) => type !== RequestTypeCode.InternationalRecruitment
                )
                .map((type) => ({
                  value: type,
                  text: {
                    fr: t(`enum.requestTypeCode.${type.toLowerCase()}_long`),
                    en: t(`enum.requestTypeCode.${type.toLowerCase()}_long`),
                  },
                }))}
              name={name}
              language={extractLanguage(i18n.language)}
              value={value as string | undefined}
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                const selectedType = event.target.value as RequestTypeCode;
                setValue('types', [selectedType]);
                if (selectedType !== RequestTypeCode.Headhunter) {
                  setValue('openToInternationalRecruitment', null);
                  setValue('conditionCandidateInHandMandatoryValue', null);
                }
                trigger(name);
              }}
              invalid={!!error}
              helperText={error?.message && tGlobal(error.message)}
              legendSize="medium"
              alignRow
            />
          )}
        />
      </SectionGridRow>

      {types?.includes(RequestTypeCode.Headhunter) && (
        <>
          <SectionGridRow className="p-s-24">
            <Controller
              name="openToInternationalRecruitment"
              control={control}
              render={({ field: { name }, fieldState: { error } }) => (
                <RadioGroup
                  name={name}
                  data={BOOLEAN_VALUES}
                  legendSize="medium"
                  legend={
                    <Trans
                      i18nKey="labels.openToInternationalRecruitment"
                      t={t}
                    />
                  }
                  legendClassName="inline"
                  language={extractLanguage(i18n.language)}
                  onChange={openToInternationalRecruitmentOnChange}
                  alignRow
                  value={openToInternationalRecruitment ?? undefined}
                  invalid={!!error}
                  helperText={error?.message && tGlobal(error?.message)}
                />
              )}
            />
          </SectionGridRow>

          <SectionGridRow className="p-s-24">
            <Controller
              name="conditionCandidateInHandMandatoryValue"
              control={control}
              render={({ field: { name }, fieldState: { error } }) => (
                <RadioGroup
                  radioParentClassName="grid grid-cols-2 mt-s-16"
                  radioClassName="min-h-48 max-h-48 justify-center"
                  name={name}
                  data={conditionCandidateInHandMandatoryData}
                  legend={t('labels.mustProposeCandidates')}
                  language={extractLanguage(i18n.language)}
                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    setValue(name, event.target.value === 'true');
                    trigger(name);
                  }}
                  legendSize="medium"
                  alignRow
                  value={proposeCandidates ?? undefined}
                  invalid={!!error}
                  helperText={error?.message && tGlobal(error?.message)}
                />
              )}
            />
          </SectionGridRow>
        </>
      )}
    </Section>
  );
};

export default HRNeedsFields;
