import { useQuery } from 'urql';

import {
  Query,
  QueryCandidateRequestChatRoomMessagesArgs,
} from '@domain/graphql.types';
import { CandidateRequestChatRoomMessages } from '@infrastructure/graphql/chat';

const useCandidateRequestChatRoomMessages = (
  input: QueryCandidateRequestChatRoomMessagesArgs
) => {
  const [result, reexecuteQuery] = useQuery<
    Pick<Query, 'candidateRequestChatRoomMessages'>,
    QueryCandidateRequestChatRoomMessagesArgs
  >({
    query: CandidateRequestChatRoomMessages,
    variables: input,
  });

  const { data, fetching, error } = result;

  return {
    data,
    fetching,
    error,
    reexecuteQuery,
  };
};

export default useCandidateRequestChatRoomMessages;
