import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'urql';

import { AccountContext, AlertContext } from '@application/context';
import MutationUpdateCandidateStatusToHired from '@infrastructure/graphql/offer/candidate/update-candidate-status-to-hired.mutation';

import { toViewModel } from '../view-models/update-candidate-status-to-hired.vm';

const useUpdateCandidateStatusToHired = () => {
  const [{ data, fetching, error }, updateCandidateStatusToHired] = useMutation(
    MutationUpdateCandidateStatusToHired
  );

  const { setAlertContent } = useContext(AlertContext);
  const { refreshAccount } = useContext(AccountContext);
  const { t } = useTranslation('candidates', { keyPrefix: 'messages' });

  const viewModel = toViewModel({
    candidate: {
      data,
      fetching,
      error,
    },
  });

  useEffect(() => {
    if (!error) return;

    setAlertContent({
      action: t('updateCandidateStatusToHiredError'),
      modifier: 'alert-error',
    });
  }, [error, setAlertContent, t]);

  useEffect(() => {
    if (data?.updateCandidateStatusToHired?.candidate) {
      setAlertContent({
        action: t('updateCandidateStatusToHiredSuccess'),
        modifier: 'alert-success',
      });
      refreshAccount();
    }
  }, [
    data?.updateCandidateStatusToHired?.candidate,
    setAlertContent,
    refreshAccount,
    t,
  ]);

  return {
    viewModel,
    updateCandidateStatusToHired,
  };
};

export default useUpdateCandidateStatusToHired;
