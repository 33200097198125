import { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Link, Player, Switcher, Tooltip } from '@application/components';
import { AccountContext, UserContext } from '@application/context';
import { PrivatePage, RootPrivatePage } from '@application/enums/pagesUrl';
import { AccountTypeCode, UserRole } from '@domain/graphql.types';

import { InProgressCandidateRequestsList } from '../candidate/request/list';
import useCheckEnterpriseDescriptionModal from '../organization/profile/hooks/useCheckEnterpriseDescriptionModal';
import { InProgressRequestsList } from '../recruitment/request/list';
import { Header, IconLink } from './components';
import { useEmailVerification } from './hooks';

const Dashboard = () => {
  const { account } = useContext(AccountContext);
  const { user, accountUser } = useContext(UserContext);
  useEmailVerification({ user });

  const { t } = useTranslation('dashboard');
  const { tryOpenModal } = useCheckEnterpriseDescriptionModal();

  const isEnterpriseAccount = account?.type === AccountTypeCode.Enterprise;

  const viewAllLink = isEnterpriseAccount
    ? RootPrivatePage.REQUESTS
    : RootPrivatePage.CANDIDATE_REQUESTS;

  const createNewRequestLink = isEnterpriseAccount
    ? PrivatePage.REQUEST_CREATE
    : PrivatePage.CANDIDATE_REQUEST_CREATE;

  const videoLink = `youtu.be/${
    isEnterpriseAccount ? 'auIxmGvQnyo' : 'C_AXj1g-OIg'
  }`;

  const quickLinks = useMemo(
    () => [
      // Not used for now, but will probably be in the futur, manage the disabled if negotiations exist.
      // {
      //   to: PrivatePage.DASHBOARD,
      //   icon: 'ri-shake-hands-line',
      //   disabled: true,
      //   text: t('button.ongoingNegotiations'),
      //   tooltip: t('messages.noOngoingNegotiations'),
      //   display: true,
      // },
      {
        to: createNewRequestLink,
        icon: 'ri-briefcase-line',
        disabled: false,
        text: t('button.createNewRequest'),
        tooltip: null,
        display: true,
        onClick: () => {
          tryOpenModal(PrivatePage.DASHBOARD);
        },
      },
      {
        to: PrivatePage.ORGANIZATION_USERS,
        icon: 'ri-user-add-line',
        disabled: false,
        text: t('button.manageAndInviteTeams'),
        tooltip: null,
        display: accountUser?.role !== UserRole.Member,
      },
    ],
    [accountUser?.role, createNewRequestLink, t, tryOpenModal]
  );

  return (
    <section>
      <Header
        userName={user?.name || ''}
        isEnterpriseAccount={isEnterpriseAccount}
      />

      <Switcher threshold="lg" limit={2} className="z-0">
        <div className="mt-s-24">
          <h2 className="text-20 font-semibold mb-s-16">
            {t('subtitle.introductionVideo')}
          </h2>

          <Player url={videoLink} />
        </div>

        <div className="mt-s-24">
          <h2 className="text-20 font-semibold">{t('subtitle.iconLinks')}</h2>

          <Box className="mt-s-16 mb-s-16 py-s-0">
            <ul className="[&>li+li]:border-t-1">
              {quickLinks.map(
                ({ to, icon, text, disabled, tooltip, display, onClick }) =>
                  display && (
                    <li className="py-s-16" key={text}>
                      {tooltip ? (
                        <Tooltip
                          message={tooltip}
                          className="!z-0"
                          position="top"
                        >
                          <IconLink
                            icon={icon}
                            disabled={disabled}
                            to={to}
                            onClick={onClick}
                          >
                            {text}
                          </IconLink>
                        </Tooltip>
                      ) : (
                        <IconLink
                          icon={icon}
                          disabled={disabled}
                          to={to}
                          onClick={onClick}
                        >
                          {text}
                        </IconLink>
                      )}
                    </li>
                  )
              )}
            </ul>
          </Box>
        </div>
      </Switcher>

      <div className="flex flex-row justify-between mb-s-16 mt-s-32">
        <h2 className="text-20 font-semibold">{t('subtitle.requests')}</h2>
        <Link to={viewAllLink}>{t('button.viewAll')}</Link>
      </div>

      {isEnterpriseAccount ? (
        <InProgressRequestsList />
      ) : (
        <InProgressCandidateRequestsList />
      )}
    </section>
  );
};

export default Dashboard;
