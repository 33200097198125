import { useMutation } from 'urql';

import { Mutation, MutationOfferUpdateSeenAtArgs } from '@domain/graphql.types';
import { MutationOfferUpdateSeenAt } from '@infrastructure/graphql/offer';

const useOfferSeenAtUpdate = () => {
  const [{ data, fetching, error }, updateOfferSeenAt] = useMutation<
    Pick<Mutation, 'offerUpdateSeenAt'>,
    MutationOfferUpdateSeenAtArgs
  >(MutationOfferUpdateSeenAt);

  return {
    updateOfferSeenAt,
    data,
    fetching,
    error,
  };
};

export default useOfferSeenAtUpdate;
