import { ChangeEvent, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useLocation } from 'react-router';

import { ButtonLink, PageHeader, PageLoader } from '@application/components';
import { Badge } from '@application/components/badge';
import { ButtonLinkCounter } from '@application/components/buttons';
import { PrivatePage, RootPrivatePage } from '@application/enums/pagesUrl';
import { getLocalizedDescription } from '@utils/i18n-utils';
import { cn } from '@utils/lib-utils';

import { ActionMenu } from './components';
import { withCandidateNegotiationProvider } from './context';
import CandidateNegotiationContext from './context/CandidateNegotiationContext';
import { CandidateRequestTable, OffersTable } from './tables';

const ViewCandidateNegotiationPage = () => {
  const { t, i18n } = useTranslation('candidates');

  const location = useLocation();

  const { isLoading, candidateRequest, checkedRooms, setCheckedRooms } =
    useContext(CandidateNegotiationContext);

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>): void => {
    if (event.target.checked) {
      setCheckedRooms([...checkedRooms, event.target.id]);
    } else {
      setCheckedRooms([...checkedRooms.filter((c) => c !== event.target.id)]);
    }
  };

  const pathToRequestChatRoom = useMemo(
    () =>
      generatePath(PrivatePage.CANDIDATE_REQUEST_CHAT, {
        id: candidateRequest?.id ?? '',
      }),
    [candidateRequest?.id]
  );

  return isLoading ? (
    <PageLoader />
  ) : (
    <>
      <PageHeader
        titleAs="h1"
        title={getLocalizedDescription(
          candidateRequest?.jobSpecialty?.descriptions,
          i18n.language
        )}
        subtitle={candidateRequest?.specialty}
        complement={
          <Badge
            className="px-s-18"
            value={candidateRequest?.candidateOffersCount.toString()}
            placeholder="0"
          />
        }
        backTo={RootPrivatePage.CANDIDATE_NEGOTIATIONS}
      >
        <ButtonLink
          className="mr-s-16"
          to={generatePath(PrivatePage.CANDIDATE_REQUEST_DETAILS, {
            id: candidateRequest?.id || '#',
          })}
          from={location.pathname}
          outline
        >
          {t('button.requestDetails')}
        </ButtonLink>

        <ButtonLinkCounter
          // count={10}
          iconName="ri-question-answer-line"
          to={pathToRequestChatRoom}
          size="medium"
          from={location.pathname}
        />
      </PageHeader>

      <div className={cn('relative', { 'mb-s-80': checkedRooms.length > 0 })}>
        <div className="overflow-x-auto">
          <div className="flex gap-s-16">
            {candidateRequest && (
              <CandidateRequestTable candidateRequest={candidateRequest} />
            )}
            <OffersTable onChange={handleOnChange} />
          </div>
        </div>
      </div>

      <ActionMenu />
    </>
  );
};

export const WrapperViewCandidateNegotiationPage =
  withCandidateNegotiationProvider(ViewCandidateNegotiationPage);
