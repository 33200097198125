import { useCallback, useEffect, useMemo, useState } from 'react';

import { PageLoader } from '@application/components';
import { RootPrivatePage } from '@application/enums/pagesUrl';
import { CandidateRequestChatRoom } from '@domain/graphql.types';

import { ChatRoom } from './components';
import { ChatContent } from './components/ChatRoom';
import {
  useCandidateRequestChatRoomMessagesList,
  useCandidateRequestChatRoomsList,
  useCreateCandidateRequestChatRoomMessage,
} from './hooks';

type CandidateOpportunityChatContentProps = {
  candidateOpportunityId: string;
  accountId: string;
  title: string;
};
const CandidateOpportunityChatContent = ({
  candidateOpportunityId,
  accountId,
  title,
}: CandidateOpportunityChatContentProps) => {
  const [selectedChatRoom, setSelectedChatRoom] =
    useState<CandidateRequestChatRoom>();
  const [agencyName, setAgencyName] = useState('');

  const { createCandidateRequestChatRoomMessage } =
    useCreateCandidateRequestChatRoomMessage();

  const {
    candidateRequestChatRooms,
    isLoading: candidateRequestChatRoomsIsLoading,
  } = useCandidateRequestChatRoomsList({
    candidateRequestId: candidateOpportunityId,
    enterpriseAccountId: accountId,
  });

  const {
    candidateRequestChatRoomMessages,
    isLoading: candidateRequestChatRoomMessagesIsLoading,
    refetchCandidateRequestChatRoomMessages,
  } = useCandidateRequestChatRoomMessagesList({
    candidateRequestChatRoomId: selectedChatRoom?.id ?? '',
  });

  const chatContent: ChatContent = useMemo(
    () => ({
      messageList: candidateRequestChatRoomMessages?.map((message) => ({
        position:
          message.accountId === accountId
            ? ('right' as const)
            : ('left' as const),
        date: message.sentAt,
        viewed: true,
        content: message.message,
      })),
    }),
    [accountId, candidateRequestChatRoomMessages]
  );

  const handleSendMessage = useCallback(
    async (message: string) => {
      await createCandidateRequestChatRoomMessage({
        input: {
          candidateRequestChatRoomId: selectedChatRoom?.id ?? '',
          message,
        },
      });

      if (candidateRequestChatRoomMessages.length < 1) {
        refetchCandidateRequestChatRoomMessages();
      }
    },
    [
      createCandidateRequestChatRoomMessage,
      selectedChatRoom?.id,
      candidateRequestChatRoomMessages.length,
      refetchCandidateRequestChatRoomMessages,
    ]
  );

  useEffect(() => {
    if (
      selectedChatRoom &&
      !selectedChatRoom?.isAgencyAnonym &&
      selectedChatRoom.agencyName
    ) {
      setAgencyName(selectedChatRoom.agencyName);
    }
  }, [
    selectedChatRoom,
    selectedChatRoom?.isAgencyAnonym,
    selectedChatRoom?.agencyName,
  ]);

  useEffect(() => {
    if (candidateRequestChatRooms?.length) {
      setSelectedChatRoom(candidateRequestChatRooms[0]);
    }
  }, [candidateRequestChatRooms]);

  return candidateRequestChatRoomsIsLoading ? (
    <PageLoader />
  ) : (
    <ChatRoom
      isLoading={candidateRequestChatRoomMessagesIsLoading}
      title={title}
      subtitle={agencyName}
      chatContent={chatContent}
      backTo={RootPrivatePage.CANDIDATE_OPPORTUNITIES}
      onSendMessage={handleSendMessage}
      refetchMessages={refetchCandidateRequestChatRoomMessages}
    />
  );
};

export default CandidateOpportunityChatContent;
