import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'urql';

import { AccountContext, AlertContext } from '@application/context';
import MutationUpdateCandidateStatusToRejected from '@infrastructure/graphql/offer/candidate/update-candidate-status-to-rejected.mutation';

import { toViewModel } from '../view-models/update-candidate-status-to-rejected.vm';

const useUpdateCandidateStatusToRejected = () => {
  const [{ data, fetching, error }, updateCandidateStatusToRejected] =
    useMutation(MutationUpdateCandidateStatusToRejected);

  const { setAlertContent } = useContext(AlertContext);
  const { refreshAccount } = useContext(AccountContext);
  const { t } = useTranslation('candidates', { keyPrefix: 'messages' });

  const viewModel = toViewModel({
    candidate: {
      data,
      fetching,
      error,
    },
  });

  useEffect(() => {
    if (!error) return;

    setAlertContent({
      action: t('updateCandidateStatusToRejectedError'),
      modifier: 'alert-error',
    });
  }, [error, setAlertContent, t]);

  useEffect(() => {
    if (data?.updateCandidateStatusToRejected?.candidate) {
      setAlertContent({
        action: t('updateCandidateStatusToRejectedSuccess'),
        modifier: 'alert-success',
      });
      refreshAccount();
    }
  }, [
    data?.updateCandidateStatusToRejected?.candidate,
    setAlertContent,
    refreshAccount,
    t,
  ]);

  return {
    viewModel,
    updateCandidateStatusToRejected,
  };
};

export default useUpdateCandidateStatusToRejected;
