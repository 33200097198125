import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'urql';

import { AccountContext, AlertContext } from '@application/context';
import MutationUpdateCandidateStatusToInterview from '@infrastructure/graphql/offer/candidate/update-candidate-status-to-interview.mutation.';

import { toViewModel } from '../view-models/update-candidate-status-to-interview.vm';

const useUpdateCandidateStatusToInterview = () => {
  const [{ data, fetching, error }, updateCandidateStatusToInterview] =
    useMutation(MutationUpdateCandidateStatusToInterview);

  const { setAlertContent } = useContext(AlertContext);
  const { refreshAccount } = useContext(AccountContext);
  const { t } = useTranslation('candidates', { keyPrefix: 'messages' });

  const viewModel = toViewModel({
    candidate: {
      data,
      fetching,
      error,
    },
  });

  useEffect(() => {
    if (!error) return;

    setAlertContent({
      action: t('updateCandidateStatusToInterviewError'),
      modifier: 'alert-error',
    });
  }, [error, setAlertContent, t]);

  useEffect(() => {
    if (data?.updateCandidateStatusToInterview?.candidate) {
      setAlertContent({
        action: t('updateCandidateStatusToInterviewSuccess'),
        modifier: 'alert-success',
      });
      refreshAccount();
    }
  }, [
    data?.updateCandidateStatusToInterview?.candidate,
    setAlertContent,
    refreshAccount,
    t,
  ]);

  return {
    viewModel,
    updateCandidateStatusToInterview,
  };
};

export default useUpdateCandidateStatusToInterview;
