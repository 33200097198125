import { useEffect, useRef } from 'react';
import { GroupBase, MenuProps } from 'react-select';

import { Menu } from './components';
import { OptionType } from './types';

const MenuWithScroll = ({
  renderMenuFooterContent,
  scrollPosition,
  setMenuListRef,
  onScroll,
}: {
  renderMenuFooterContent?: React.ReactNode;
  scrollPosition: number;
  setMenuListRef: (ref: HTMLDivElement | null) => void;
  onScroll: (position: number) => void;
}) => {
  const menuRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const menuListElement =
      menuRef.current?.querySelector('.select__menu-list');
    if (!menuListElement) {
      return () => {};
    }

    menuListElement.scrollTop = scrollPosition;
    setMenuListRef(menuListElement as HTMLDivElement);

    const handleScroll = () => {
      onScroll((menuListElement as HTMLElement).scrollTop);
      setMenuListRef(menuListElement as HTMLDivElement);
    };

    menuListElement.addEventListener('scroll', handleScroll);
    return () => menuListElement.removeEventListener('scroll', handleScroll);
  }, [setMenuListRef, scrollPosition, onScroll]);

  return {
    MenuComponent: (
      props: MenuProps<OptionType, boolean, GroupBase<OptionType>>
    ) => {
      const onMouseDownCapture = () => {
        const menuListElement =
          menuRef.current?.querySelector('.select__menu-list');
        if (menuListElement) {
          onScroll((menuListElement as HTMLElement).scrollTop);
        }
      };

      return (
        <div ref={menuRef} onMouseDownCapture={onMouseDownCapture}>
          {Menu(renderMenuFooterContent)(props)}
        </div>
      );
    },
  };
};

export default MenuWithScroll;
