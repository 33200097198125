import { gql } from '@urql/core';

const MutationUpdateCandidatePrivateNotes = gql`
  mutation UpdateCandidatePrivateNotes(
    $updateCandidatePrivateNotesInput: UpdateCandidatePrivateNotesInput!
  ) {
    updateCandidatePrivateNotes(
      updateCandidatePrivateNotesInput: $updateCandidatePrivateNotesInput
    ) {
      candidate {
        id
        privateNotes
      }
    }
  }
`;

export default MutationUpdateCandidatePrivateNotes;
