import { gql } from 'urql';

const MutationCandidateRequestChatRoomMessageCreate = gql`
  mutation CandidateRequestChatRoomMessageCreate(
    $input: CandidateRequestChatRoomMessageCreateInput!
  ) {
    candidateRequestChatRoomMessageCreate(input: $input) {
      message {
        id
      }
    }
  }
`;

export default MutationCandidateRequestChatRoomMessageCreate;
