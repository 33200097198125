import { useTranslation } from 'react-i18next';

import { RecruitmentNegotiationRoom } from '@domain/graphql.types';
import { cn } from '@utils/lib-utils';

import { TH_COL_CLASS_NAME } from '../constants';

const TableHeader = ({ room }: { room: RecruitmentNegotiationRoom }) => {
  const { t } = useTranslation('recruitment', {
    keyPrefix: 'negotiation.tableHeaders',
  });

  const { t: tGlobal } = useTranslation();

  return (
    <thead>
      <tr>
        <th
          className={cn(
            'w-s-192 min-w-[12rem] max-w-[12rem]',
            TH_COL_CLASS_NAME
          )}
        >
          <span className="sr-only">{t('underNegotiation')}</span>
        </th>

        <th className={TH_COL_CLASS_NAME}>
          <span>
            {tGlobal(
              `enum.recruitmentNegotiationRoomStatusTypeCode.${room?.status.toLowerCase()}`
            )}

            {(room?.rejectionExplanation || room.rejectionReason) &&
              `: ${
                room?.rejectionExplanation ||
                tGlobal(`enum.rejectionReason.${room?.rejectionReason}`)
              }`}
          </span>
        </th>
      </tr>
    </thead>
  );
};

export default TableHeader;
