import { useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import {
  AccountContext,
  ModalContext,
  UserContext,
} from '@application/context';
import { PrivatePage } from '@application/enums/pagesUrl';
import { UserRole } from '@domain/graphql.types';

import { EnterpriseDescriptionModal } from '../components';
import { EnterpriseDescriptionModalConst } from '../constants';

const useCheckEnterpriseDescriptionModal = () => {
  const { t } = useTranslation('organization', {
    keyPrefix: 'checkEnterpriseDescriptionModal',
  });

  const { account } = useContext(AccountContext);
  const { accountUser } = useContext(UserContext);
  const { setModal } = useContext(ModalContext);

  const navigate = useNavigate();

  const handleOnConfirm = useCallback(() => {
    navigate(PrivatePage.ORGANIZATION_PROFILE, {
      state: { from: EnterpriseDescriptionModalConst },
    });
    setModal(null);
  }, [navigate, setModal]);

  const tryOpenModal = useCallback(
    (navigateBackTo?: string) => {
      if (
        !account?.employerOfferDescription ||
        account?.employerOfferDescription.length < 500
      ) {
        setModal({
          title: t('title'),
          content: (
            <EnterpriseDescriptionModal
              onClose={() => {
                setModal(null);
                if (navigateBackTo) {
                  navigate(navigateBackTo);
                }
              }}
              onConfirm={handleOnConfirm}
              isMemberRole={accountUser?.role === UserRole.Member}
            />
          ),
          maxWidth: 'fit',
          modifier: 'warning',
        });
      } else {
        navigate(PrivatePage.REQUEST_CREATE);
      }
    },
    [
      account?.employerOfferDescription,
      accountUser?.role,
      handleOnConfirm,
      navigate,
      setModal,
      t,
    ]
  );

  return {
    tryOpenModal,
  };
};

export default useCheckEnterpriseDescriptionModal;
