import { useQuery } from 'urql';

import {
  Query,
  QueryCandidateRequestChatRoomsArgs,
} from '@domain/graphql.types';
import { CandidateRequestChatRooms } from '@infrastructure/graphql/chat';

const useCandidateRequestChatRooms = (
  input: QueryCandidateRequestChatRoomsArgs
) => {
  const [result, reexecuteQuery] = useQuery<
    Pick<Query, 'candidateRequestChatRooms'>,
    QueryCandidateRequestChatRoomsArgs
  >({
    query: CandidateRequestChatRooms,
    variables: input,
  });

  const { data, fetching, error } = result;

  return {
    data,
    fetching,
    error,
    reexecuteQuery,
  };
};

export default useCandidateRequestChatRooms;
