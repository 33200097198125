import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  generatePath,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router';
import { validate as isUUID } from 'uuid';

import { ButtonLink, PageHeader, PageLoader } from '@application/components';
import { Badge } from '@application/components/badge';
import { ButtonLinkCounter } from '@application/components/buttons';
import { PrivatePage, RootPrivatePage } from '@application/enums/pagesUrl';
import { useGetRequest } from '@application/views/recruitment/request/hooks';
import { getLocalizedDescription } from '@utils/i18n-utils';
import { isDefined } from '@utils/type-utils';

import { ComparisonTable } from './table';

const OffersComparisonPage = () => {
  const { t, i18n } = useTranslation('offers', {
    keyPrefix: 'comparison',
  });
  const { id = '' } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!(id && isUUID(id))) {
      navigate(RootPrivatePage.NOT_FOUND, { replace: true });
    }
  }, [id, navigate]);

  const {
    viewModel: { data, isLoading },
    refetchRequest,
  } = useGetRequest(id);

  const pathToRecruitmentOpportunityChatRoom = useMemo(
    () =>
      generatePath(PrivatePage.REQUEST_CHAT, {
        id: data?.id ?? '',
      }),
    [data?.id]
  );

  return isLoading ? (
    <PageLoader />
  ) : (
    <>
      <PageHeader
        titleAs="h1"
        title={getLocalizedDescription(
          data?.jobSpecialty?.descriptions,
          i18n.language
        )}
        subtitle={data?.operationUnit?.name}
        complement={
          <Badge
            className="px-s-18"
            value={data?.offersCount.toString()}
            placeholder="0"
          />
        }
        backTo={RootPrivatePage.REQUESTS}
      >
        <div className="flex gap-s-16">
          {isDefined(data?.recruitmentNegotiation) && (
            <ButtonLink
              primary
              icon={<i className="ri-shake-hands-line mr-s-4 font-normal" />}
              to={generatePath(PrivatePage.RECRUITMENT_NEGOTIATION_DETAILS, {
                id: data?.recruitmentNegotiation?.id || null,
              })}
              from={location.pathname}
            >
              {t('buttons.negotiationsZone')}
            </ButtonLink>
          )}

          <ButtonLinkCounter
            iconName="ri-question-answer-line"
            to={pathToRecruitmentOpportunityChatRoom}
            size="medium"
            from={location.pathname}
          />
        </div>
      </PageHeader>
      <div className="mb-s-40">
        <h1 className="h2 mb-s-16 text-center">
          {t('comparisonTableExplanation.title')}
        </h1>

        <p className="pl-s-32 pr-s-32 mb-s-24 text-16 text-neutral-secondary whitespace-pre-wrap">
          {t('comparisonTableExplanation.text1')}
        </p>

        <p className="pl-s-32 pr-s-32 text-16 text-neutral-secondary whitespace-pre-wrap">
          {t('comparisonTableExplanation.text2')}
        </p>
      </div>
      {data && (
        <ComparisonTable request={data} refetchRequest={refetchRequest} />
      )}
    </>
  );
};

export default OffersComparisonPage;
