import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useLocation } from 'react-router';

import { Box, Link } from '@application/components';
import { BadgeList } from '@application/components/badge';
import { ButtonLinkCounter } from '@application/components/buttons';
import { CandidateRequestTypeBadge } from '@application/components/request-type-badges';
import { PrivatePage } from '@application/enums/pagesUrl';
import { CandidateNegotiationRoom } from '@domain/graphql.types';

import { useRoomListCard } from '../hooks';

type RoomListCardProps = {
  data: CandidateNegotiationRoom;
};

const RoomListCard = ({ data }: RoomListCardProps) => {
  const location = useLocation();
  const { t: tGlobal } = useTranslation();

  const { operationTerritories, pathToRoom, specialty, timeSinceLastActivity } =
    useRoomListCard(data);

  const pathToRecruitmentOpportunityChatRoom = useMemo(
    () =>
      generatePath(PrivatePage.CANDIDATE_OPPORTUNITY_CHAT, {
        id: data.candidateRequest.id,
      }),
    [data.candidateRequest.id]
  );

  return (
    <Box className="min-w-[66rem] w-fit h-s-96 shadow-xs">
      <div className="grid grid-cols-[4fr_1fr_1fr] items-center">
        <div className="flex flex-col gap-s-8">
          <Link
            to={pathToRoom}
            className="no-underline hover:underline focus:underline"
          >
            <span className="text-18 font-semibold">{specialty}</span>
          </Link>

          <div className="flex gap-s-8 items-center">
            <BadgeList values={operationTerritories} />

            <i className="ri-circle-fill text-6" />

            <span className="text-14">
              {tGlobal('date.latestActivity', {
                date: tGlobal(`date.options.${timeSinceLastActivity.unit}`, {
                  count: timeSinceLastActivity.value,
                }),
              })}
            </span>
          </div>
        </div>

        {data?.candidateRequest.type ? (
          <CandidateRequestTypeBadge type={data.candidateRequest.type} />
        ) : (
          <span>-</span>
        )}

        <div className="flex justify-end gap-s-16">
          <ButtonLinkCounter
            count={data.notificationCount}
            iconName="ri-alarm-warning-line"
            to={pathToRoom}
          />

          <ButtonLinkCounter
            // count={10}
            iconName="ri-question-answer-line"
            to={pathToRecruitmentOpportunityChatRoom}
            from={location.pathname}
          />
        </div>
      </div>
    </Box>
  );
};

export default RoomListCard;
