import { gql } from '@urql/core';

const MutationUpdateCandidateStatusToRejected = gql`
  mutation UpdateCandidateStatusToRejected(
    $updateCandidateStatusToRejectedInput: UpdateCandidateStatusToRejectedInput!
  ) {
    updateCandidateStatusToRejected(
      updateCandidateStatusToRejectedInput: $updateCandidateStatusToRejectedInput
    ) {
      candidate {
        id
        status
      }
    }
  }
`;

export default MutationUpdateCandidateStatusToRejected;
