import { Trans, useTranslation } from 'react-i18next';

import { Button, Modal } from '@application/components';

export type EnterpriseDescriptionModalProps = {
  onClose: () => void;
  onConfirm: () => void;
  isMemberRole: boolean;
};

const EnterpriseDescriptionModal = ({
  onClose,
  onConfirm,
  isMemberRole,
}: EnterpriseDescriptionModalProps) => {
  const { t: tGlobal } = useTranslation();
  const { t } = useTranslation('organization', {
    keyPrefix: 'checkEnterpriseDescriptionModal',
  });

  return (
    <div>
      {!isMemberRole ? (
        t('content')
      ) : (
        <p className="whitespace-pre-line">
          <Trans i18nKey="memberContent" t={t} />
        </p>
      )}
      <Modal.CloseBtn onClick={onClose} />

      <Modal.Action>
        {!isMemberRole && (
          <Button onClick={onClose}>{tGlobal('button.cancel')}</Button>
        )}

        <Button primary onClick={!isMemberRole ? onConfirm : onClose}>
          {!isMemberRole
            ? tGlobal('button.continue')
            : tGlobal('button.confirm')}
        </Button>
      </Modal.Action>
    </div>
  );
};

export default EnterpriseDescriptionModal;
