import { useTranslation } from 'react-i18next';

import MessageDisplay from '@application/components/messageDisplay/MessageDisplay';
import { Table } from '@application/components/table';

import { useOfferCandidatesList } from '../hooks/useOfferCandidatesList';

const OfferCandidatesList = ({
  offerId,
  withResume,
  noCandidatesExplaination,
  requestId,
  disabledAction,
}: {
  offerId: string;
  withResume?: boolean;
  noCandidatesExplaination?: string;
  requestId?: string;
  disabledAction?: boolean;
}) => {
  const { columns, offerCandidates, isLoading } = useOfferCandidatesList(
    {
      offerId,
      withResume,
    },
    requestId,
    disabledAction
  );

  const { t } = useTranslation('offers', {
    keyPrefix: 'candidates',
  });

  return offerCandidates?.length > 0 ? (
    <Table data={offerCandidates} columns={columns} isLoading={isLoading} />
  ) : (
    noCandidatesExplaination && (
      <MessageDisplay
        label={t('list.noCandidatesExplaination')}
        text={noCandidatesExplaination}
      />
    )
  );
};

export default OfferCandidatesList;
