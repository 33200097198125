import { useCandidateRequestChatRoomMessages } from '@domain/chat';
import { CandidateRequestChatRoomMessagesInput } from '@domain/graphql.types';

import { toViewModel } from '../../view-models/message/get-candidate-request-chat-room-message.vm';

const useGetCandidateRequestChatRoomMessages = (
  input: CandidateRequestChatRoomMessagesInput
) => {
  const { data, fetching, error, reexecuteQuery } =
    useCandidateRequestChatRoomMessages({
      input,
    });

  const viewModel = toViewModel({
    candidateRequestChatRoomMessages: {
      data,
      fetching,
      error,
    },
  });

  return {
    viewModel,
    refetchCandidateRequestChatRoomMessages: reexecuteQuery,
  };
};

export default useGetCandidateRequestChatRoomMessages;
