import { gql } from '@urql/core';

const MutationUpdateCandidateStatusToHired = gql`
  mutation UpdateCandidateStatusToHired(
    $updateCandidateStatusToHiredInput: UpdateCandidateStatusToHiredInput!
  ) {
    updateCandidateStatusToHired(
      updateCandidateStatusToHiredInput: $updateCandidateStatusToHiredInput
    ) {
      candidate {
        id
        status
      }
    }
  }
`;

export default MutationUpdateCandidateStatusToHired;
