import { GroupedOptionType } from '@application/components/select-field/select/types';
import { extractLanguage } from '@utils/i18n-utils';

const mapOptions = (
  data: any[],
  language: string
): { value: any; label: string }[] => {
  if (!data || !data.length) return [];

  return data.map(({ value, text }: any) => ({
    value,
    label: text[extractLanguage(language)],
  }));
};

export const mapGroupedOptions = (
  data: any[],
  language: string
): GroupedOptionType[] => {
  if (!data?.length) return [];
  interface GroupData {
    groupText: string;
    items: any[];
  }

  const groupedData = data.reduce<Record<string, GroupData>>((result, item) => {
    const groupText = item.groupText[extractLanguage(language)];

    return {
      ...result,
      [groupText]: {
        groupText,
        items: result[groupText] ? [...result[groupText].items, item] : [item],
      },
    };
  }, {});

  const result = Object.entries(groupedData).map(([groupText, groupData]) => ({
    label: groupText,
    options: groupData.items.map(({ value, text }) => ({
      value,
      label: text[extractLanguage(language)],
    })),
  }));

  return result;
};

export default mapOptions;
