import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback, useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { UseQueryExecute } from 'urql';

import { SectionContainer } from '@application/components';
import { Button } from '@application/components/buttons';
import { UserRoleGuard } from '@application/components/guards';
import { SocialNetworksSection } from '@application/components/slices';
import { useIsUserRoleAuthorized } from '@application/hooks';
import { UserRole } from '@domain/graphql.types';

import {
  EnterpriseDescriptionModalConst,
  PROFILE_DEFAULT_VALUES,
} from '../constants';
import { useUpdateProfile } from '../hooks';
import { normalizeUpdateInputData } from '../normalizers';
import { PROFILE_SCHEMA } from '../schema';
import { OrganizationProfile, OrganizationProfileFormFields } from '../types';
import { GeneralInformationFields } from './fields';

type ProfileFormProps = {
  values: OrganizationProfile | undefined;
  refetchAccount: UseQueryExecute;
};

const ProfileForm = ({ values, refetchAccount }: ProfileFormProps) => {
  const { t: tGlobal } = useTranslation();
  const { t } = useTranslation('organization', {
    keyPrefix: 'updateProfileForm',
  });

  const { updateProfile } = useUpdateProfile();
  const authorized = useIsUserRoleAuthorized({
    authorizedUserRoles: [UserRole.SuperAdmin, UserRole.Owner, UserRole.Admin],
  });

  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isDirty },
    reset,
    setValue,
    watch,
    trigger,
  } = useForm<OrganizationProfileFormFields>({
    defaultValues: {
      ...PROFILE_DEFAULT_VALUES,
    },
    values,
    mode: 'onBlur',
    resolver: yupResolver(PROFILE_SCHEMA),
  });

  const location = useLocation();
  const from = location.state?.from;

  const employerOfferDescription = watch('employerOfferDescription');

  useEffect(() => {
    if (from === EnterpriseDescriptionModalConst) {
      trigger('employerOfferDescription');
    }
  }, [trigger, errors, from, employerOfferDescription]);

  const onSubmit: SubmitHandler<OrganizationProfileFormFields> = useCallback(
    async (data) => {
      await updateProfile({
        accountUpdateInput: normalizeUpdateInputData(data),
      });
      refetchAccount();
    },
    [refetchAccount, updateProfile]
  );

  const handleReset = () => {
    reset();
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      data-testid="TEST-update-organization-profile"
    >
      <SectionContainer
        titleAs="h2"
        title={t('generalInformation.title')}
        description={t('generalInformation.description')}
      >
        <GeneralInformationFields
          control={control}
          register={register}
          errors={errors}
          setValue={setValue}
          watch={watch}
          disabled={!authorized}
        />
      </SectionContainer>

      <hr className="my-s-40" />

      <SocialNetworksSection
        register={register}
        errors={errors}
        disabled={!authorized}
      />

      <UserRoleGuard
        authorizedUserRoles={[
          UserRole.SuperAdmin,
          UserRole.Owner,
          UserRole.Admin,
        ]}
      >
        <div className="flex justify-end gap-s-16 w-full mt-s-40">
          <Button type="button" onClick={handleReset} disabled={!isDirty}>
            {tGlobal('button.discardChanges')}
          </Button>

          <Button type="submit" primary>
            {tGlobal('button.save')}
          </Button>
        </div>
      </UserRoleGuard>
    </form>
  );
};

export default ProfileForm;
