import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import { PageLoader } from '@application/components';
import { AccountContext } from '@application/context';
import { getLocalizedDescription } from '@utils/i18n-utils';

import { useGetCandidateOpportunity } from '../candidate/opportunity/hooks';
import CandidateOpportunityChatContent from './CandidateOpportunityChatContent';

const CandidateOpportunityChatPage = () => {
  const { account } = useContext(AccountContext);
  const { id = '' } = useParams();
  const { i18n } = useTranslation();

  const {
    viewModel: {
      data: candidateOpportunityData,
      isLoading: isCandidateOpportunityLoading,
    },
  } = useGetCandidateOpportunity(id);

  return !isCandidateOpportunityLoading &&
    candidateOpportunityData &&
    account ? (
    <CandidateOpportunityChatContent
      candidateOpportunityId={id}
      accountId={account.id}
      title={
        getLocalizedDescription(
          candidateOpportunityData.jobSpecialty?.descriptions,
          i18n.language
        ) ?? ''
      }
    />
  ) : (
    <PageLoader />
  );
};

export default CandidateOpportunityChatPage;
