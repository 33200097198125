import { gql } from 'urql';

const RequestChatRoomMessages = gql`
  query RequestChatRoomMessages($input: RequestChatRoomMessagesInput!) {
    requestChatRoomMessages(input: $input) {
      results {
        id
        requestChatRoomId
        message
        sentAt
        isSystem
        accountId
        userId
      }
      totalCount
    }
  }
`;

export default RequestChatRoomMessages;
