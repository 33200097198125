import { gql } from 'urql';

const MutationCandidateRequestChatRoomCreate = gql`
  mutation CandidateRequestChatRoomCreate(
    $input: CandidateRequestChatRoomCreateInput!
  ) {
    candidateRequestChatRoomCreate(input: $input) {
      chatRoom {
        id
      }
    }
  }
`;

export default MutationCandidateRequestChatRoomCreate;
