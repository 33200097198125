import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useLocation } from 'react-router';

import { Button, ButtonLink, SectionGrid } from '@application/components';
import { PrivatePage } from '@application/enums/pagesUrl';
import {
  Offer,
  OfferStatusTypeCode,
  Request,
  RequestTypeCode,
} from '@domain/graphql.types';

import GeneralConditions from './GeneralConditions';
import HeadhunterConditions from './HeadhunterConditions';
import InternationalRecruitmentConditions from './InternationalRecruitmentConditions';
import OutsourcingConditions from './OutsourcingConditions';
import TemporaryPlacementConditions from './TemporaryPlacementConditions';

type ConditionsSummaryProps = {
  data: Offer | undefined;
  request: Request | undefined;
  onClickInviteNegoRoom: () => void;
};

const ConditionsSummary = ({
  data,
  request,
  onClickInviteNegoRoom,
}: ConditionsSummaryProps) => {
  const { t } = useTranslation('requests');
  const location = useLocation();

  const middlePart = useMemo(
    () =>
      data?.negotiationRoomId ? (
        <ButtonLink
          icon={<i className="ri-shake-hands-line" />}
          to={generatePath(PrivatePage.RECRUITMENT_NEGOTIATION_DETAILS, {
            id: request?.recruitmentNegotiation?.id || '#',
          })}
          primary
          from={location.pathname}
        >
          {t('button.goToNegoRoom')}
        </ButtonLink>
      ) : (
        data?.status !== OfferStatusTypeCode.Accepted &&
        data?.status !== OfferStatusTypeCode.Rejected && (
          <Button
            primary
            icon={<i className="ri-shake-hands-line" />}
            onClick={onClickInviteNegoRoom}
          >
            {t('button.negoRoomInvitation')}
          </Button>
        )
      ),

    [
      data?.negotiationRoomId,
      data?.status,
      location.pathname,
      onClickInviteNegoRoom,
      request?.recruitmentNegotiation?.id,
      t,
    ]
  );

  const conditions = useMemo(
    () => [
      {
        title: 'conditions',
        middleContent: middlePart,
        children: <GeneralConditions data={data} request={request} />,
        isVisible: true,
      },
      {
        title: 'headHunter',
        children: <HeadhunterConditions data={data} request={request} />,
        isVisible:
          request?.types?.includes(RequestTypeCode.Headhunter) ||
          data?.requestTypes?.includes(RequestTypeCode.Headhunter),
      },
      {
        title: 'internationalRecruitment',
        children: (
          <InternationalRecruitmentConditions data={data} request={request} />
        ),
        isVisible:
          request?.types?.includes(RequestTypeCode.InternationalRecruitment) ||
          data?.requestTypes?.includes(
            RequestTypeCode.InternationalRecruitment
          ),
      },
      {
        title: 'outsourcing',
        children: <OutsourcingConditions data={data} request={request} />,
        isVisible:
          request?.types?.includes(RequestTypeCode.Outsourcing) ||
          data?.requestTypes?.includes(RequestTypeCode.Outsourcing),
      },
      {
        title: 'temporaryPlacement',
        children: (
          <TemporaryPlacementConditions data={data} request={request} />
        ),
        isVisible:
          request?.types?.includes(RequestTypeCode.TemporaryHelp) ||
          data?.requestTypes?.includes(RequestTypeCode.TemporaryHelp),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data, t]
  );

  return (
    <div>
      {conditions.map(
        (c) =>
          c.isVisible && (
            <div key={c.title}>
              <div className="flex justify-between items-center py-s-6 pl-s-16 pr-s-44 border border-b-transparent rounded-t-md bg-secondary mt-s-32">
                <span className="text-18 font-semibold">
                  {t(`subtitles.${c.title}`)}
                </span>
                {c.middleContent}
                <span className="text-center whitespace-pre-line">
                  {t(`subtitles.initialConditions`)}
                </span>
              </div>
              <SectionGrid
                readOnly
                title={t(`subtitles.${c.title}`)}
                hideTitle
                gridColsClassName="grid-cols-[1fr_10rem]"
              >
                {c.children}
              </SectionGrid>
            </div>
          )
      )}
    </div>
  );
};

export default ConditionsSummary;
