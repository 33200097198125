import { gql } from 'urql';

import { DescriptionsFragment } from '@infrastructure/graphql/descriptions';

const OperationTerritories = gql`
  ${DescriptionsFragment}

  query OperationTerritories {
    operationTerritories {
      values {
        code
        descriptions {
          ...DescriptionsFragment
        }
        provinceCode
        provinceDescriptions {
          ...DescriptionsFragment
        }
      }
    }
  }
`;

export default OperationTerritories;
