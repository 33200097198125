import { useCallback, useContext, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { UseQueryExecute } from 'urql';

import { ConfirmationModal, PageLoader, Stack } from '@application/components';
import { ModalContext, NavContext } from '@application/context';
import { Request } from '@domain/graphql.types';
import { getLocalizedDescription } from '@utils/i18n-utils';
import { cn } from '@utils/lib-utils';

import { ActionMenu, TableBody, TableHeader } from '../components';
import { useGetRequestOffers } from '../hooks';
import useAddRoomsToRecruitmentNegotiation from '../hooks/useAddRoomsToRecruitmentNegotiation';
import useCreateRecruitmentNegotiation from '../hooks/useCreateRecruitmentNegotiation';

type ComparisonTableProps = {
  request: Request;
  refetchRequest: UseQueryExecute;
};

const ComparisonTable = ({ request, refetchRequest }: ComparisonTableProps) => {
  const [comparing, setComparing] = useState<boolean>();
  const [checkedOffers, setCheckedOffers] = useState<string[]>([]);

  const { id } = useParams();

  const { t, i18n } = useTranslation('offers', {
    keyPrefix: 'comparison',
  });
  const { isBottomMenuOpen } = useContext(NavContext);
  const { setModal } = useContext(ModalContext);

  const {
    viewModel: { data: offers = [], isLoading },
    refreshRequestOffers,
  } = useGetRequestOffers(id || '');

  const { createRecruitmentNegotiation } = useCreateRecruitmentNegotiation();

  const { addRoomsToRecruitmentNegotiation } =
    useAddRoomsToRecruitmentNegotiation();

  // const handleOnChange = (event: ChangeEvent<HTMLInputElement>): void => {
  //   if (event.target.checked) {
  //     setCheckedOffers([...checkedOffers, event.target.id]);
  //   } else {
  //     setCheckedOffers([...checkedOffers.filter((c) => c !== event.target.id)]);
  //   }
  // };

  const confirmInviteNegoRoom = useCallback(async () => {
    await createRecruitmentNegotiation({
      input: {
        offerIds: [...checkedOffers],
        requestId: request.id,
      },
    });
    refetchRequest();
  }, [checkedOffers, createRecruitmentNegotiation, refetchRequest, request.id]);

  const confirmInviteExistingNegoRoom = useCallback(async () => {
    // Should only be called when there is already an existing negotiation and we only select offers which are not in the negotiation yet.
    if (
      request.recruitmentNegotiation?.id &&
      !offers.some((o) => checkedOffers.includes(o.id) && o.negotiationRoomId)
    ) {
      await addRoomsToRecruitmentNegotiation({
        input: {
          offerIds: checkedOffers,
          recruitmentNegotiationId: request.recruitmentNegotiation?.id,
        },
      });
      refetchRequest();
    }
  }, [
    addRoomsToRecruitmentNegotiation,
    checkedOffers,
    offers,
    refetchRequest,
    request.recruitmentNegotiation?.id,
  ]);

  const handleClickInviteNegoRoom = useCallback(() => {
    if (!request.recruitmentNegotiation) {
      setModal({
        title: t('modal.confirmInviteNegoRoomTitle'),
        content: (
          <ConfirmationModal
            content={
              <Stack>
                <p>
                  <Trans
                    t={t}
                    i18nKey="modal.confirmInviteNegoRoomContent"
                    values={{
                      specialty: getLocalizedDescription(
                        request?.jobSpecialty?.descriptions,
                        i18n.language
                      ),
                    }}
                  />
                </p>
                <p>{t('modal.confirmInviteNegoRoomContentAdditional')}</p>
              </Stack>
            }
            onCancel={() => setModal(null)}
            onConfirm={() => {
              confirmInviteNegoRoom();
              setModal(null);
            }}
          />
        ),
      });
    } else {
      setModal({
        title: t('modal.confirmInviteExistingNegoRoomTitle'),
        content: (
          <ConfirmationModal
            content={
              <Stack>
                <p>
                  <Trans
                    t={t}
                    i18nKey="modal.confirmInviteExistingNegoRoomContent"
                    values={{
                      specialty: getLocalizedDescription(
                        request?.jobSpecialty?.descriptions,
                        i18n.language
                      ),
                    }}
                  />
                </p>
                <p>{t('modal.confirmInviteNegoRoomContentAdditional')}</p>
              </Stack>
            }
            onCancel={() => setModal(null)}
            onConfirm={() => {
              confirmInviteExistingNegoRoom();
              setModal(null);
            }}
          />
        ),
      });
    }
  }, [
    confirmInviteExistingNegoRoom,
    confirmInviteNegoRoom,
    i18n.language,
    request?.jobSpecialty?.descriptions,
    request.recruitmentNegotiation,
    setModal,
    t,
  ]);

  const displayOffers = useMemo(
    () =>
      (comparing
        ? offers.filter((o) => checkedOffers.includes(o.id))
        : offers) || [],
    [comparing, checkedOffers, offers]
  );

  const marginBottom = useMemo(
    () => (isBottomMenuOpen ? 'mb-s-80' : 'mb-s-0'),
    [isBottomMenuOpen]
  );

  return isLoading ? (
    <PageLoader />
  ) : (
    <>
      <div className={cn('relative', marginBottom)}>
        <div className="overflow-x-auto">
          <table data-testid="TEST-offers-table" className="w-full bg-base-100">
            <TableHeader
              request={request}
              // comparing={comparing}
              offers={displayOffers}
              checkedOffers={checkedOffers}
              // onChange={handleOnChange}
            />

            <TableBody
              request={request}
              offers={displayOffers}
              checkedOffers={checkedOffers}
            />
          </table>
        </div>
      </div>

      <ActionMenu
        checkedOffers={checkedOffers}
        comparing={comparing}
        onInviteClicked={handleClickInviteNegoRoom}
        onComparisonClicked={() => setComparing(!comparing)}
        onDeselectAll={() => setCheckedOffers([])}
        offers={offers}
        request={request}
        refreshRequestOffers={refreshRequestOffers}
      />
    </>
  );
};

export default ComparisonTable;
