import { useTranslation } from 'react-i18next';

import { NoContent, Table } from '@application/components/table';
import useCheckEnterpriseDescriptionModal from '@application/views/organization/profile/hooks/useCheckEnterpriseDescriptionModal';
import { RequestStatusTypeCode } from '@domain/graphql.types';

import { useRequestsList } from '../hooks/useRequestsList';

const InProgressRequestsList = () => {
  const { t } = useTranslation('requests', { keyPrefix: 'list.noContent' });

  const {
    columns,
    currentPage,
    requests,
    sorting,
    count,
    onSortingChange,
    onPaginationChange,
    onPaginationReset,
    isLoading,
  } = useRequestsList({ status: [RequestStatusTypeCode.InProgress] });

  const { tryOpenModal } = useCheckEnterpriseDescriptionModal();

  return (
    <Table
      data={requests}
      columns={columns}
      count={count}
      onSortingChange={onSortingChange}
      sortingState={sorting}
      paginationState={currentPage}
      onPaginationChange={onPaginationChange}
      onPaginationReset={onPaginationReset}
      isRowColorChangeOnHover
      noDataComponent={
        <NoContent
          title={t('in_progress.title')}
          buttonLabel={t('in_progress.button')}
          onClick={tryOpenModal}
        />
      }
      isLoading={isLoading}
    />
  );
};

export default InProgressRequestsList;
