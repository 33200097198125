import { Notification } from '@magicbell/magicbell-react';
import { TFunction } from 'i18next';
import { generatePath } from 'react-router';

import { PrivatePage, RootPrivatePage } from '@application/enums/pagesUrl';
import { Descriptions } from '@domain/graphql.types';
import { getLocalizedDescription } from '@utils/i18n-utils';

import { CategoryMap } from './types';

export const categoryConfig: CategoryMap = {
  offer_accepted: {
    titleKey: 'notifications.category.offers',
    icon: 'shake-hands',
    getActionUrl: ({ actionUrl }) => {
      if (!actionUrl) {
        return '#';
      }

      return new URL(actionUrl).pathname;
    },
    getMessage: (
      t: TFunction,
      language: string,
      { customAttributes }: Notification
    ) => {
      if (typeof customAttributes === 'string' || !customAttributes) {
        return '';
      }

      const { accountName } = customAttributes;
      const requestName = getLocalizedDescription(
        customAttributes.jobSpecialty as Descriptions,
        language
      );

      return t('notifications.messages.offerAccepted', {
        accountName,
        requestName,
      });
    },
  },
  offer_rejected: {
    titleKey: 'notifications.category.offers',
    icon: 'shake-hands',
    getActionUrl: ({ actionUrl }) => {
      if (!actionUrl) {
        return '#';
      }

      return new URL(actionUrl).pathname;
    },
    getMessage: (
      t: TFunction,
      language: string,
      { customAttributes }: Notification
    ) => {
      if (typeof customAttributes === 'string' || !customAttributes) {
        return '';
      }

      const { accountName } = customAttributes;
      const requestName = getLocalizedDescription(
        customAttributes.jobSpecialty as Descriptions,
        language
      );

      return t('notifications.messages.offerRejected', {
        accountName,
        requestName,
      });
    },
  },
  offer_created: {
    titleKey: 'notifications.category.offers',
    icon: 'money-dollar-circle',
    getActionUrl: ({ actionUrl }) => {
      if (!actionUrl) {
        return RootPrivatePage.REQUESTS;
      }

      return new URL(actionUrl).pathname;
    },
    getMessage: (
      t: TFunction,
      language: string,
      { customAttributes }: Notification
    ) => {
      if (typeof customAttributes === 'string' || !customAttributes) {
        return '';
      }

      const { accountName } = customAttributes;
      const requestName = getLocalizedDescription(
        customAttributes.jobSpecialty as Descriptions,
        language
      );

      return t('notifications.messages.offerCreated', {
        accountName,
        requestName,
      });
    },
  },
  offer_updated: {
    titleKey: 'notifications.category.offers',
    icon: 'money-dollar-circle',
    getActionUrl: ({ actionUrl }) => {
      if (!actionUrl) {
        return RootPrivatePage.REQUESTS;
      }

      return new URL(actionUrl).pathname;
    },
    getMessage: (
      t: TFunction,
      language: string,
      { customAttributes }: Notification
    ) => {
      if (typeof customAttributes === 'string' || !customAttributes) {
        return '';
      }

      const requestName = getLocalizedDescription(
        customAttributes.jobSpecialty as Descriptions,
        language
      );

      return t('notifications.messages.offerUpdated', {
        accountName: customAttributes.offerAccountName,
        requestName,
      });
    },
  },
  offer_candidate_rejected: {
    titleKey: 'notifications.category.offerCandidateRejected',
    icon: 'user-2',
    getActionUrl: ({ actionUrl }) => {
      if (!actionUrl) {
        return RootPrivatePage.OFFERS;
      }

      return new URL(actionUrl).pathname;
    },
    getMessage: (
      t: TFunction,
      _: string,
      { customAttributes }: Notification
    ) => {
      if (typeof customAttributes === 'string' || !customAttributes) {
        return '';
      }

      return t('notifications.messages.offerCandidateRejected', {
        candidateName: customAttributes.candidateName,
      });
    },
  },
  offer_candidate_hired: {
    titleKey: 'notifications.category.offerCandidateHired',
    icon: 'user-2',
    getActionUrl: ({ actionUrl }) => {
      if (!actionUrl) {
        return RootPrivatePage.OFFERS;
      }

      return new URL(actionUrl).pathname;
    },
    getMessage: (
      t: TFunction,
      _: string,
      { customAttributes }: Notification
    ) => {
      if (typeof customAttributes === 'string' || !customAttributes) {
        return '';
      }

      return t('notifications.messages.offerCandidateHired', {
        candidateName: customAttributes.candidateName,
      });
    },
  },
  candidate_offer_created: {
    titleKey: 'notifications.category.candidateOffers',
    icon: 'money-dollar-circle',
    getActionUrl: ({ actionUrl }) => {
      if (!actionUrl) {
        return RootPrivatePage.CANDIDATE_REQUESTS;
      }

      return new URL(actionUrl).pathname;
    },
    getMessage: (
      t: TFunction,
      _: string,
      { customAttributes }: Notification
    ) => {
      if (typeof customAttributes === 'string' || !customAttributes) {
        return '';
      }

      const { accountName } = customAttributes;
      const candidateName = `${customAttributes.firstName} ${customAttributes.lastName}`;

      return t('notifications.messages.candidateOfferCreated', {
        accountName,
        candidateName,
      });
    },
  },
  candidate_offer_updated: {
    titleKey: 'notifications.category.candidateOffers',
    icon: 'money-dollar-circle',
    getActionUrl: ({ actionUrl }) => {
      if (!actionUrl) {
        return RootPrivatePage.CANDIDATE_REQUESTS;
      }

      return new URL(actionUrl).pathname;
    },
    getMessage: (
      t: TFunction,
      _: string,
      { customAttributes }: Notification
    ) => {
      if (typeof customAttributes === 'string' || !customAttributes) {
        return '';
      }

      const candidateName = `${customAttributes.firstName} ${customAttributes.lastName}`;

      return t('notifications.messages.candidateOfferUpdated', {
        accountName: customAttributes.candidateOfferAccountName,
        candidateName,
      });
    },
  },
  request_created: {
    titleKey: 'notifications.category.request',
    icon: 'suitcase',
    getActionUrl: ({ topic: id }) =>
      id
        ? generatePath(PrivatePage.RECRUITMENT_OPPORTUNITY_DETAILS, { id })
        : RootPrivatePage.RECRUITMENT_OPPORTUNITIES,
    getMessage: (
      t: TFunction,
      language: string,
      { customAttributes }: Notification
    ) => {
      if (typeof customAttributes === 'string' || !customAttributes) {
        return '';
      }

      const requestName = getLocalizedDescription(
        customAttributes.jobSpecialty as Descriptions,
        language
      );

      return t('notifications.messages.requestCreated', { requestName });
    },
  },
  request_updated: {
    titleKey: 'notifications.category.request',
    icon: 'suitcase',
    getActionUrl: ({ topic: id }) =>
      id
        ? generatePath(PrivatePage.RECRUITMENT_OPPORTUNITY_DETAILS, { id })
        : RootPrivatePage.RECRUITMENT_OPPORTUNITIES,
    getMessage: (
      t: TFunction,
      language: string,
      { customAttributes }: Notification
    ) => {
      if (typeof customAttributes === 'string' || !customAttributes) {
        return '';
      }

      const requestName = getLocalizedDescription(
        customAttributes.jobSpecialty as Descriptions,
        language
      );

      return t('notifications.messages.requestUpdated', { requestName });
    },
  },
  recruitment_negotiation_room_opened: {
    titleKey: 'notifications.category.negotiationRoom',
    icon: 'shake-hands',
    getActionUrl: ({ actionUrl }) => {
      if (!actionUrl) {
        return RootPrivatePage.RECRUITMENT_NEGOTIATION_ROOMS;
      }

      return new URL(actionUrl).pathname;
    },
    getMessage: (
      t: TFunction,
      language: string,
      { customAttributes }: Notification
    ) => {
      if (typeof customAttributes === 'string' || !customAttributes) {
        return '';
      }

      const requestName = getLocalizedDescription(
        customAttributes.jobSpecialty as Descriptions,
        language
      );

      return t('notifications.messages.recruitmentNegotiationRoomOpened', {
        requestName,
      });
    },
  },
  recruitment_negotiation_room_updated: {
    titleKey: 'notifications.category.negotiationRoom',
    icon: 'shake-hands',
    getActionUrl: ({ actionUrl }) => {
      if (!actionUrl) {
        return '#';
      }

      return new URL(actionUrl).pathname;
    },
    getMessage: (
      t: TFunction,
      language: string,
      { customAttributes }: Notification
    ) => {
      if (typeof customAttributes === 'string' || !customAttributes) {
        return '';
      }

      const { accountName } = customAttributes;
      const requestName = getLocalizedDescription(
        customAttributes.jobSpecialty as Descriptions,
        language
      );

      return t('notifications.messages.recruitmentNegotiationRoomUpdated', {
        accountName,
        requestName,
      });
    },
  },
  recruitment_negotiation_room_rejected: {
    titleKey: 'notifications.category.negotiationRoom',
    icon: 'shake-hands',
    getActionUrl: ({ actionUrl }) => {
      if (!actionUrl) {
        return RootPrivatePage.RECRUITMENT_NEGOTIATION_ROOMS;
      }

      return new URL(actionUrl).pathname;
    },
    getMessage: (
      t: TFunction,
      language: string,
      { customAttributes }: Notification
    ) => {
      if (typeof customAttributes === 'string' || !customAttributes) {
        return '';
      }

      const { accountName } = customAttributes;
      const requestName = getLocalizedDescription(
        customAttributes.jobSpecialty as Descriptions,
        language
      );

      return t('notifications.messages.recruitmentNegotiationRoomRejected', {
        accountName,
        requestName,
      });
    },
  },
  recruitment_negotiation_completed: {
    titleKey: 'notifications.category.negotiationRoom',
    icon: 'shake-hands',
    getActionUrl: ({ actionUrl }) => {
      if (!actionUrl) {
        return '#';
      }

      return new URL(actionUrl).pathname;
    },
    getMessage: (
      t: TFunction,
      language: string,
      { customAttributes }: Notification
    ) => {
      if (typeof customAttributes === 'string' || !customAttributes) {
        return '';
      }

      const { accountName } = customAttributes;
      const requestName = getLocalizedDescription(
        customAttributes.jobSpecialty as Descriptions,
        language
      );

      return t('notifications.messages.recruitmentNegotiationCompleted', {
        accountName,
        requestName,
      });
    },
  },
  request_chat_room_message_received_by_enterprise: {
    titleKey: 'notifications.category.request',
    icon: 'briefcase',
    getActionUrl: ({ actionUrl }) => {
      if (!actionUrl) {
        return RootPrivatePage.REQUESTS;
      }

      const url = new URL(actionUrl).pathname;
      if (window.location.pathname === url) {
        window.location.reload();
      }

      return url;
    },
    getMessage: (
      t: TFunction,
      language: string,
      { customAttributes }: Notification
    ) => {
      if (typeof customAttributes === 'string' || !customAttributes) {
        return '';
      }

      const requestName = getLocalizedDescription(
        customAttributes.jobSpecialty as Descriptions,
        language
      );

      return t(
        'notifications.messages.requestChatRoomMessageReceivedByEnterprise',
        {
          accountName: customAttributes.senderAccountName,
          requestName,
        }
      );
    },
  },
  request_chat_room_message_received_by_agency: {
    titleKey: 'notifications.category.opportunity',
    icon: 'alarm-warning',
    getActionUrl: ({ actionUrl }) => {
      if (!actionUrl) {
        return RootPrivatePage.RECRUITMENT_OPPORTUNITIES;
      }

      const url = new URL(actionUrl).pathname;
      if (window.location.pathname === url) {
        window.location.reload();
      }

      return url;
    },
    getMessage: (
      t: TFunction,
      language: string,
      { customAttributes }: Notification
    ) => {
      if (typeof customAttributes === 'string' || !customAttributes) {
        return '';
      }

      const requestName = getLocalizedDescription(
        customAttributes.jobSpecialty as Descriptions,
        language
      );

      return t(
        `notifications.messages.requestChatRoomMessageReceivedByAgency`,
        {
          accountName: customAttributes.senderAccountName,
          requestName,
        }
      );
    },
  },
  candidate_request_chat_room_message_received_by_enterprise: {
    titleKey: 'notifications.category.candidateOpportunity',
    icon: 'alarm-warning',
    getActionUrl: ({ actionUrl }) => {
      if (!actionUrl) {
        return RootPrivatePage.CANDIDATE_OPPORTUNITIES;
      }

      const url = new URL(actionUrl).pathname;
      if (window.location.pathname === url) {
        window.location.reload();
      }

      return url;
    },
    getMessage: (
      t: TFunction,
      language: string,
      { customAttributes }: Notification
    ) => {
      if (typeof customAttributes === 'string' || !customAttributes) {
        return '';
      }

      const jobSpecialty = getLocalizedDescription(
        customAttributes.jobSpecialty as Descriptions,
        language
      );

      return t(
        'notifications.messages.candidateRequestChatRoomMessageReceivedByEnterprise',
        {
          accountName: customAttributes.senderAccountName,
          jobSpecialty,
        }
      );
    },
  },
  candidate_request_chat_room_message_received_by_agency: {
    titleKey: 'notifications.category.candidateRequest',
    icon: 'briefcase',
    getActionUrl: ({ actionUrl }) => {
      if (!actionUrl) {
        return RootPrivatePage.CANDIDATE_REQUESTS;
      }

      const url = new URL(actionUrl).pathname;
      if (window.location.pathname === url) {
        window.location.reload();
      }

      return url;
    },
    getMessage: (
      t: TFunction,
      language: string,
      { customAttributes }: Notification
    ) => {
      if (typeof customAttributes === 'string' || !customAttributes) {
        return '';
      }

      const jobSpecialty = getLocalizedDescription(
        customAttributes.jobSpecialty as Descriptions,
        language
      );

      return t(
        `notifications.messages.candidateRequestChatRoomMessageReceivedByAgency`,
        {
          accountName: customAttributes.senderAccountName,
          candidateName: customAttributes.candidateName,
          jobSpecialty,
        }
      );
    },
  },
  candidate_negotiation_room_opened: {
    titleKey: 'notifications.category.negotiationRoom',
    icon: 'shake-hands',
    getActionUrl: ({ actionUrl }) => {
      if (!actionUrl) {
        return RootPrivatePage.CANDIDATE_NEGOTIATION_ROOMS;
      }

      return new URL(actionUrl).pathname;
    },
    getMessage: (
      t: TFunction,
      _: string,
      { customAttributes }: Notification
    ) => {
      if (typeof customAttributes === 'string' || !customAttributes) {
        return '';
      }

      const candidateName = `${customAttributes.firstName} ${customAttributes.lastName}`;

      return t('notifications.messages.candidateNegotiationRoomOpened', {
        candidateName,
      });
    },
  },
  candidate_negotiation_room_updated: {
    titleKey: 'notifications.category.negotiationRoom',
    icon: 'shake-hands',
    getActionUrl: ({ actionUrl }) => {
      if (!actionUrl) {
        return '#';
      }

      return new URL(actionUrl).pathname;
    },
    getMessage: (
      t: TFunction,
      _: string,
      { customAttributes }: Notification
    ) => {
      if (typeof customAttributes === 'string' || !customAttributes) {
        return '';
      }

      const { accountName } = customAttributes;
      const candidateName = `${customAttributes.firstName} ${customAttributes.lastName}`;

      return t('notifications.messages.candidateNegotiationRoomUpdated', {
        accountName,
        candidateName,
      });
    },
  },
  candidate_negotiation_room_rejected: {
    titleKey: 'notifications.category.negotiationRoom',
    icon: 'shake-hands',
    getActionUrl: ({ actionUrl }) => {
      if (!actionUrl) {
        return RootPrivatePage.CANDIDATE_NEGOTIATION_ROOMS;
      }

      return new URL(actionUrl).pathname;
    },
    getMessage: (
      t: TFunction,
      _: string,
      { customAttributes }: Notification
    ) => {
      if (typeof customAttributes === 'string' || !customAttributes) {
        return '';
      }

      const { accountName } = customAttributes;
      const candidateName = `${customAttributes.firstName} ${customAttributes.lastName}`;

      return t('notifications.messages.candidateNegotiationRoomRejected', {
        accountName,
        candidateName,
      });
    },
  },
  candidate_negotiation_completed: {
    titleKey: 'notifications.category.negotiationRoom',
    icon: 'shake-hands',
    getActionUrl: ({ actionUrl }) => {
      if (!actionUrl) {
        return '#';
      }

      return new URL(actionUrl).pathname;
    },
    getMessage: (
      t: TFunction,
      _: string,
      { customAttributes }: Notification
    ) => {
      if (typeof customAttributes === 'string' || !customAttributes) {
        return '';
      }

      const { accountName } = customAttributes;
      const candidateName = `${customAttributes.firstName} ${customAttributes.lastName}`;

      return t('notifications.messages.candidateNegotiationCompleted', {
        accountName,
        candidateName,
      });
    },
  },
  candidate_request_matched: {
    titleKey: 'notifications.category.candidateRequest',
    icon: 'suitcase',
    getActionUrl: ({ actionUrl }) => {
      if (!actionUrl) {
        return RootPrivatePage.CANDIDATE_OPPORTUNITIES;
      }

      return new URL(actionUrl).pathname;
    },
    getMessage: (t: TFunction) =>
      t('notifications.messages.candidateRequestMatched'),
  },
};
