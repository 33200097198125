import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';

import { NoContent, Table } from '@application/components/table';
import useCheckEnterpriseDescriptionModal from '@application/views/organization/profile/hooks/useCheckEnterpriseDescriptionModal';
import { RequestsFilters } from '@domain/graphql.types';

import { FiltersFields } from '../components';
import { useRequestFilters } from '../hooks';
import { useRequestsList } from '../hooks/useRequestsList';

const RequestsList = () => {
  const [tableFilters, setTableFilters] = useState<RequestsFilters>();

  const { t } = useTranslation('requests', { keyPrefix: 'list.noContent' });

  const location = useLocation();

  const hash = useMemo(
    () =>
      location.hash ? location.hash.replace('#', '').replace('-', '_') : 'all',
    [location]
  );

  const {
    columns,
    currentPage,
    requests,
    sorting,
    count,
    onSortingChange,
    onPaginationChange,
    onPaginationReset,
    isLoading,
  } = useRequestsList(tableFilters);

  const { filters, updateSelectFilters, updateTextFilter } = useRequestFilters({
    resetPagination: onPaginationReset,
  });

  const { tryOpenModal } = useCheckEnterpriseDescriptionModal();

  useEffect(() => {
    setTableFilters(filters);
  }, [filters]);

  return (
    <>
      <div className="grow w-full mb-s-16">
        <FiltersFields
          updateTextFilter={updateTextFilter}
          updateSelectFilters={updateSelectFilters}
        />
      </div>

      <div
        className="relative mt-s-32"
        id="requests-list"
        aria-labelledby={
          filters.status?.length
            ? `tab-${String(filters.status).toLowerCase()}`
            : 'tab-all'
        }
        role="tabpanel"
      >
        <Table
          data={requests}
          columns={columns}
          count={count}
          onSortingChange={onSortingChange}
          sortingState={sorting}
          paginationState={currentPage}
          onPaginationChange={onPaginationChange}
          onPaginationReset={onPaginationReset}
          isRowColorChangeOnHover
          noDataComponent={
            <NoContent
              title={t(`${hash}.title`)}
              buttonLabel={t(`${hash}.button`)}
              onClick={tryOpenModal}
            />
          }
          isLoading={isLoading}
        />
      </div>
    </>
  );
};

export default RequestsList;
