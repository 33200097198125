import { useMutation } from 'urql';

import {
  CandidateRequestChatRoomMessageResponse,
  MutationCandidateRequestChatRoomMessageCreateArgs,
} from '@domain/graphql.types';
import { CandidateRequestChatRoomMessageCreate } from '@infrastructure/graphql/chat';

const useCandidateRequestChatRoomMessageCreate = () => {
  const [{ data, fetching, error }, createCandidateRequestChatRoomMessage] =
    useMutation<
      CandidateRequestChatRoomMessageResponse,
      MutationCandidateRequestChatRoomMessageCreateArgs
    >(CandidateRequestChatRoomMessageCreate);

  return {
    createCandidateRequestChatRoomMessage,
    data,
    fetching,
    error,
  };
};

export default useCandidateRequestChatRoomMessageCreate;
