import { useCallback, useContext, useMemo } from 'react';
import {
  Control,
  Controller,
  FieldErrors,
  UseFormRegister,
  UseFormSetValue,
  UseFormTrigger,
  UseFormWatch,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  TextAreaField,
  TextInputField,
  Tooltip,
} from '@application/components';
import { Button } from '@application/components/buttons';
import { Stack, Switcher } from '@application/components/container-layouts';
import { DropdownRange } from '@application/components/dropdown-range';
import { SelectField } from '@application/components/select-field';
import { OptionType } from '@application/components/select-field/select';
import { AccountContext, ModalContext } from '@application/context';
import { mapOptions } from '@application/utils';
import {
  CreateOperationUnitModal,
  CreateSpecialtyModal,
  useGetIndustrySectors,
  useGetJobSpecialties,
} from '@application/views/organization';
import { formattedFutureDate, formattedNowDate } from '@utils/date-utils';
import { extractLanguage } from '@utils/i18n-utils';

import { Section } from '../../components';
import useGetEducationLevels from '../../hooks/useGetEducationLevels';
import { RequestFormFields } from '../../schema';

type JobInformationFieldsProps = {
  control: Control<RequestFormFields, any>;
  errors: FieldErrors<RequestFormFields>;
  register: UseFormRegister<RequestFormFields>;
  setValue: UseFormSetValue<RequestFormFields>;
  watch: UseFormWatch<RequestFormFields>;
  trigger: UseFormTrigger<RequestFormFields>;
};

const JobInformationFields = ({
  control,
  errors,
  register,
  setValue,
  watch,
  trigger,
}: JobInformationFieldsProps) => {
  const { t, i18n } = useTranslation('requests');
  const { t: tGlobal } = useTranslation();

  const { account, refreshAccount } = useContext(AccountContext);
  const { setModal } = useContext(ModalContext);

  const specialty = watch('jobSpecialtyCode');
  const jobExperienceLevelMin = watch('jobExperienceLevelMin');
  const jobExperienceLevelMax = watch('jobExperienceLevelMax');

  const OPERATION_UNITS_OPTIONS = useMemo(
    () =>
      account?.operationUnits?.map((op) => ({
        value: op.id,
        label: op.name,
      })) || [],
    [account]
  );

  const { preferredSpecialtyCodes } = useContext(AccountContext);

  const { data: JOB_SPECIALTIES = [], isLoading: specialtiesLoading } =
    useGetJobSpecialties();

  const JOB_SPECIALTIES_OPTIONS = useMemo(() => {
    const specialties =
      preferredSpecialtyCodes.length > 0
        ? JOB_SPECIALTIES.filter(
            (s) =>
              preferredSpecialtyCodes.includes(s.code) || s.code === specialty
          )
        : [...JOB_SPECIALTIES];

    return specialties.map(({ descriptions, code }) => ({
      value: code,
      label: descriptions[extractLanguage(i18n.language)],
    }));
  }, [JOB_SPECIALTIES, i18n.language, preferredSpecialtyCodes, specialty]);

  const { data: INDUSTRY_SECTORS = [], isLoading: sectorsLoading } =
    useGetIndustrySectors();

  const SECTORS_OPTIONS = useMemo(
    () =>
      INDUSTRY_SECTORS.map(({ descriptions, code }) => ({
        value: code,
        label: descriptions[extractLanguage(i18n.language)],
      })),
    [INDUSTRY_SECTORS, i18n.language]
  );

  const { data: EDUCATION_LEVELS = [] } = useGetEducationLevels();
  const EDUCATION_LEVELS_OPTIONS = useMemo(
    () => mapOptions(EDUCATION_LEVELS, i18n.language),
    [EDUCATION_LEVELS, i18n]
  );

  const onSubmitSpecialty = useCallback(
    (jobSpecialtyId?: string) => {
      refreshAccount();
      setModal(null);
      setValue(
        'jobSpecialtyCode',
        JOB_SPECIALTIES.find((js) => js.id === jobSpecialtyId)?.code
      );
    },
    [JOB_SPECIALTIES, refreshAccount, setModal, setValue]
  );

  const handleSpecialtyModal = useCallback(() => {
    setModal({
      title: t('modal.titles.searchSpecialty'),
      content: (
        <CreateSpecialtyModal
          afterSubmit={onSubmitSpecialty}
          onClose={() => setModal(null)}
        />
      ),
    });
  }, [onSubmitSpecialty, setModal, t]);

  const onSubmitOperationUnit = useCallback(
    (operationUnitId: string) => {
      setValue('operationUnitId', operationUnitId);
      setModal(null);
    },
    [setModal, setValue]
  );

  const handleOperationUnitModal = useCallback(() => {
    setModal({
      title: t('modal.titles.createOperationUnit'),
      maxWidth: '2xl',
      content: (
        <CreateOperationUnitModal
          afterSubmit={onSubmitOperationUnit}
          onClose={() => setModal(null)}
        />
      ),
    });
  }, [onSubmitOperationUnit, setModal, t]);

  const handleOnOperationUnitIdChange = useCallback(
    (onChange: (...event: any[]) => void, currentValue: any) =>
      (option: any) => {
        if (
          !option?.value ||
          (option?.value && option.value !== currentValue)
        ) {
          if (option?.value) {
            const operationUnits = account?.operationUnits?.find(
              (s) => s.id === option.value
            );
            if (operationUnits?.sectorCode) {
              setValue('sectorCode', operationUnits?.sectorCode);
            }
          }
        }
        onChange(option?.value || undefined);
      },
    [account, setValue]
  );

  return (
    <>
      <Section legend={t('subtitles.jobInformation')} className="mb-s-12">
        <Controller
          name="jobSpecialtyCode"
          control={control}
          render={({ field: { onChange, name, value } }) => (
            <SelectField
              className="lg:w-2/3"
              label={t('labels.job')}
              name={name}
              options={JOB_SPECIALTIES_OPTIONS}
              value={
                JOB_SPECIALTIES_OPTIONS.find((s) => s.value === value) || null
              }
              onChange={(option: any) => onChange(option?.value || null)}
              renderMenuFooterContent={
                <Button
                  className="px-s-8"
                  icon={<i className="ri-add-line" />}
                  size="x-small"
                  ghost
                  onClick={handleSpecialtyModal}
                >
                  {t('labels.chooseAnOtherSpecialty')}
                </Button>
              }
              searchable
              clearable
              invalid={!!errors.jobSpecialtyCode}
              helperText={
                errors.jobSpecialtyCode?.message &&
                tGlobal(errors.jobSpecialtyCode?.message)
              }
              disabled={specialtiesLoading}
            />
          )}
        />
      </Section>
      <Section>
        <Stack space={24}>
          <Switcher space={24} limit={3}>
            <TextInputField
              label={t('labels.jobInternalTitleOptional')}
              invalid={!!errors.jobInternalTitle}
              helperText={
                errors.jobInternalTitle?.message &&
                tGlobal(errors.jobInternalTitle?.message, { max: '128' })
              }
              {...register('jobInternalTitle')}
            />

            <TextInputField
              label={
                <div className="flex items-center">
                  {t('labels.specialty')}
                  <Tooltip message={t('labels.specialtyTooltips')}>
                    <i className="ri-information-line ml-s-8 text-20 leading-none text-neutral-secondary" />
                  </Tooltip>
                </div>
              }
              invalid={!!errors.specialty}
              helperText={
                errors.specialty?.message &&
                tGlobal(errors.specialty?.message, { max: '128' })
              }
              {...register('specialty')}
            />
          </Switcher>
          <Controller
            name="jobDescription"
            control={control}
            render={({ field: { onChange, name, value } }) => (
              <TextAreaField
                name={name}
                value={value || ''}
                className="mt-s-0"
                label={
                  <div className="flex items-center">
                    {t('labels.jobDescription')}
                    <Tooltip message={t('labels.jobDescriptionTooltips')}>
                      <i className="ri-information-line ml-s-8 text-20 leading-none text-neutral-secondary" />
                    </Tooltip>
                  </div>
                }
                rows={6}
                maxChar={3000}
                onChange={onChange}
                invalid={!!errors.jobDescription}
                helperText={
                  errors.jobDescription?.message &&
                  tGlobal(errors.jobDescription?.message, {
                    max: '3000',
                    min: '200',
                  })
                }
              />
            )}
          />
          <Switcher space={24} limit={3}>
            <TextInputField
              type="date"
              minValue={formattedNowDate()}
              maxValue={formattedFutureDate(14)}
              label={
                <div className="flex items-center">
                  {t('labels.deadline')}
                  <Tooltip message={t('labels.deadlineTooltips')}>
                    <i className="ri-information-line ml-s-8 text-20 leading-none text-neutral-secondary" />
                  </Tooltip>
                </div>
              }
              invalid={!!errors.receivingOfferDeadline}
              helperText={
                errors.receivingOfferDeadline?.message &&
                tGlobal(errors.receivingOfferDeadline?.message)
              }
              {...register('receivingOfferDeadline')}
            />
            <TextInputField
              type="date"
              minValue={formattedNowDate()}
              label={t('labels.startDate')}
              {...register('desiredStartDate')}
            />
          </Switcher>
          <Switcher space={24} limit={3}>
            <Controller
              name="operationUnitId"
              control={control}
              render={({ field: { onChange, value, name } }) => (
                <SelectField
                  label={t('labels.location')}
                  name={name}
                  options={OPERATION_UNITS_OPTIONS}
                  value={
                    OPERATION_UNITS_OPTIONS.find((o) => o.value === value) ||
                    null
                  }
                  onChange={handleOnOperationUnitIdChange(onChange, value)}
                  renderMenuFooterContent={
                    <Button
                      className="px-s-8"
                      icon={<i className="ri-add-line" />}
                      size="x-small"
                      ghost
                      onClick={handleOperationUnitModal}
                    >
                      {t('labels.addNewOperationUnit')}
                    </Button>
                  }
                  invalid={!!errors.operationUnitId}
                  helperText={
                    errors.operationUnitId?.message &&
                    tGlobal(errors.operationUnitId?.message)
                  }
                  clearable
                  searchable
                />
              )}
            />
            <Controller
              name="sectorCode"
              control={control}
              render={({ field: { onChange, name, value } }) => (
                <SelectField
                  className="w-full"
                  label={t('labels.sectorCode')}
                  name={name}
                  options={SECTORS_OPTIONS}
                  value={SECTORS_OPTIONS.find((o) => o.value === value) || null}
                  onChange={(option: any) => onChange(option?.value || null)}
                  searchable
                  clearable
                  disabled={sectorsLoading}
                  invalid={!!errors.sectorCode}
                  helperText={
                    errors.sectorCode?.message &&
                    tGlobal(errors.sectorCode?.message)
                  }
                />
              )}
            />
          </Switcher>

          <Switcher space={24} limit={2}>
            <Controller
              name="desiredEducationLevelCodes"
              control={control}
              render={({ field: { onChange, name, value } }) => (
                <SelectField
                  label={t('labels.desiredEducationalLevels')}
                  name={name}
                  options={EDUCATION_LEVELS_OPTIONS}
                  value={
                    EDUCATION_LEVELS_OPTIONS.filter((l) =>
                      value?.includes(l.value)
                    ) || null
                  }
                  onChange={(options: any) =>
                    onChange(
                      options
                        ? options.map((option: OptionType) => option.value)
                        : null
                    )
                  }
                  invalid={!!errors.desiredEducationLevelCodes}
                  helperText={
                    errors.desiredEducationLevelCodes?.message &&
                    tGlobal(errors.desiredEducationLevelCodes?.message)
                  }
                  searchable
                  clearable
                  multiple
                  limitTags={0}
                  className="flex-1 min-w-fit"
                />
              )}
            />
            <TextInputField
              label={
                <div className="flex items-center">
                  {t('labels.desiredStudyField')}
                  <Tooltip message={t('labels.desiredStudyFieldTooltips')}>
                    <i className="ri-information-line ml-s-8 text-20 leading-none text-neutral-secondary" />
                  </Tooltip>
                </div>
              }
              invalid={!!errors.desiredStudyField}
              helperText={
                errors.desiredStudyField?.message &&
                tGlobal(errors.desiredStudyField?.message, { max: '128' })
              }
              {...register('desiredStudyField')}
            />
          </Switcher>

          <Switcher space={24} limit={2}>
            <DropdownRange
              label={t('labels.experienceLevel')}
              labelRange={t('labels.experienceLevelRange')}
              placeholder={
                jobExperienceLevelMin !== null && jobExperienceLevelMax !== null
                  ? `${jobExperienceLevelMin}-${jobExperienceLevelMax} ${t('suffix.years')}`
                  : ''
              }
              minLabel={t('labels.experienceLevelMin')}
              maxLabel={t('labels.experienceLevelMax')}
              minName="jobExperienceLevelMin"
              maxName="jobExperienceLevelMax"
              minValue={
                jobExperienceLevelMin !== null
                  ? jobExperienceLevelMin
                  : undefined
              }
              maxValue={
                jobExperienceLevelMax !== null
                  ? jobExperienceLevelMax
                  : undefined
              }
              defaultMinValue={1}
              defaultMaxValue={6}
              min={0}
              max={45}
              step={1}
              setValue={(
                rangeName: string,
                rangeValue: number | null | string
              ) => {
                setValue(rangeName as keyof RequestFormFields, rangeValue);
                trigger(rangeName as keyof RequestFormFields);
              }}
              invalid={
                !!errors.jobExperienceLevelMin || !!errors.jobExperienceLevelMax
              }
              helperText={
                (errors.jobExperienceLevelMin?.message &&
                  tGlobal(errors.jobExperienceLevelMin?.message)) ||
                (errors.jobExperienceLevelMax?.message &&
                  tGlobal(errors.jobExperienceLevelMax?.message))
              }
            />
            <TextInputField
              type="number"
              minValue={1}
              label={t('labels.positions')}
              invalid={!!errors.jobOpenPositions}
              helperText={
                errors.jobOpenPositions?.message &&
                tGlobal(errors.jobOpenPositions?.message, { min: '1' })
              }
              {...register('jobOpenPositions')}
            />
          </Switcher>
        </Stack>
      </Section>
    </>
  );
};

export default JobInformationFields;
