import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { generatePath, useLocation } from 'react-router';

import { Link, Tooltip } from '@application/components';
import { TooltipEllipsis } from '@application/components/tooltip-ellipsis';
import { PrivatePage } from '@application/enums/pagesUrl';
import { OfferCandidate, OfferCandidatesFilters } from '@domain/graphql.types';

import { useGetOfferCandidates } from '../../../candidate/hooks';

const columnIds = {
  name: 'name',
  experience: 'experience',
  status: 'status',
  rejectionReason: 'rejectionReason',
} as const;

const columnHelper = createColumnHelper<OfferCandidate>();

const getColumns = (
  t: TFunction<'translation', undefined>,
  tGlobal: TFunction<'translation', undefined>
) => [
  columnHelper.accessor(
    (row) => (
      <Link
        from={useLocation().pathname}
        to={generatePath(PrivatePage.VIEW_OFFER_CANDIDATE, {
          candidateId: row.id,
        })}
      >
        {`${row.firstName} ${row.lastName}`}
      </Link>
    ),
    {
      id: columnIds.name,
      cell: (info) => (
        <div className="flex gap-s-8 items-center">
          {info.row.original.metAt && (
            <Tooltip
              message={t('certifiedResume', {
                date: new Date(info.row.original.metAt),
              })}
            >
              <i className="ri-verified-badge-fill text-18" />
            </Tooltip>
          )}

          <span>{info.getValue() || '-'}</span>
        </div>
      ),
      header: () => t('list.candidate'),
      size: 300,
      minSize: 300,
      maxSize: 300,
    }
  ),
  columnHelper.accessor((row) => row.experience, {
    id: columnIds.experience,
    cell: (info) => (
      <span>
        {info.getValue() !== undefined
          ? t('list.experienceYears', { count: info.getValue() as number })
          : '-'}
      </span>
    ),
    header: () => t('list.experience'),
    size: 180,
    minSize: 180,
    maxSize: 180,
  }),
  columnHelper.accessor((row) => row.status, {
    id: columnIds.status,
    cell: (info) => (
      <span>
        {info.getValue() !== undefined
          ? tGlobal(`enum.offerCandidateStatusTypeCode.${info.getValue()}`)
          : '-'}
      </span>
    ),
    header: () => t('list.status'),
    size: 200,
    minSize: 200,
    maxSize: 200,
  }),
  columnHelper.accessor((row) => row.rejectionReason, {
    id: columnIds.rejectionReason,
    cell: (info) => {
      const rejectionReason =
        info.row.original?.rejectionExplanation ||
        tGlobal(`enum.candidateRejectionReason.${info.getValue()}`);

      return info.getValue() ? (
        <TooltipEllipsis lineClamp={5} toolTip={rejectionReason}>
          <span>{rejectionReason}</span>
        </TooltipEllipsis>
      ) : (
        '-'
      );
    },
    header: () => t('list.informations'),
    size: 400,
    minSize: 400,
    maxSize: 400,
  }),
];

type UseAccountPreferredSpecialtiesListProps = {
  columnIds: typeof columnIds;
  columns: ColumnDef<OfferCandidate>[];
  isLoading: boolean;
  offerCandidates: OfferCandidate[];
};

export const useOfferCandidatesList = (
  filters: OfferCandidatesFilters
): UseAccountPreferredSpecialtiesListProps => {
  const { t } = useTranslation('offers', {
    keyPrefix: 'candidates',
  });

  const { t: tGlobal } = useTranslation();

  const {
    viewModel: { data, isLoading },
  } = useGetOfferCandidates(filters);

  const columns = getColumns(t, tGlobal);

  return {
    columnIds,
    columns: columns as ColumnDef<OfferCandidate>[],
    isLoading,
    offerCandidates: data,
  };
};
