import { UseQueryExecute } from 'urql';

import {
  CandidateRequestChatRoomMessage,
  CandidateRequestChatRoomMessagesInput,
} from '@domain/graphql.types';

import useGetCandidateRequestChatRoomMessages from './useGetCandidateRequestChatRoomMessages';

type UseCandidateRequestChatRoomMessagesListProps = {
  count?: number;
  candidateRequestChatRoomMessages: CandidateRequestChatRoomMessage[];
  isLoading: boolean;
  refetchCandidateRequestChatRoomMessages: UseQueryExecute;
};

const useCandidateRequestChatRoomMessagesList = (
  input: CandidateRequestChatRoomMessagesInput
): UseCandidateRequestChatRoomMessagesListProps => {
  const {
    viewModel: { data, isLoading },
    refetchCandidateRequestChatRoomMessages,
  } = useGetCandidateRequestChatRoomMessages(input);

  return {
    count: data?.candidateRequestChatRoomMessages.totalCount,
    candidateRequestChatRoomMessages:
      data?.candidateRequestChatRoomMessages.results.slice() || [],
    isLoading,
    refetchCandidateRequestChatRoomMessages,
  };
};

export default useCandidateRequestChatRoomMessagesList;
