import * as yup from 'yup';

export const REJECT_REASON_SCHEMA = yup.object({
  rejectionReason: yup.string().required('validations.required'),
  otherReason: yup
    .string()
    .nullable()
    .when('rejectionReason', ([rejectionReason], schema) =>
      rejectionReason === 'other'
        ? schema.required('validations.required')
        : schema.nullable()
    ),
});

export type RejectionReasonFormData = yup.InferType<
  typeof REJECT_REASON_SCHEMA
>;
