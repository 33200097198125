import { useTranslation } from 'react-i18next';

import { Button, StatusTabs } from '@application/components';
import { RootPrivatePage } from '@application/enums/pagesUrl';
import useCheckEnterpriseDescriptionModal from '@application/views/organization/profile/hooks/useCheckEnterpriseDescriptionModal';
import { RequestStatusTypeCode } from '@domain/graphql.types';

import { RequestsList } from './list';

const RequestListPage = () => {
  const { t } = useTranslation('requests');

  const { tryOpenModal } = useCheckEnterpriseDescriptionModal();

  return (
    <>
      <div className="flex w-full justify-between mb-s-32">
        <StatusTabs
          t={t}
          type="request"
          mainPath={RootPrivatePage.REQUESTS}
          statuses={[
            RequestStatusTypeCode.Agreement,
            RequestStatusTypeCode.Draft,
            RequestStatusTypeCode.InProgress,
          ]}
        />

        <Button
          onClick={() => tryOpenModal()}
          icon={<i className="ri-file-add-line" />}
          primary
        >
          {t('button.newRequest')}
        </Button>
      </div>

      <RequestsList />
    </>
  );
};

export default RequestListPage;
