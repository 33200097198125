import { useEffect } from 'react';

import useOfferSeenAtUpdate from '@domain/offer/useOfferSeenAtUpdate';

type UseOfferSeenAtViewModel = {
  data: any;
  isLoading: boolean;
  error?: Error;
};

const toViewModel = ({
  offer: { data, fetching, error },
}: {
  offer: {
    data: any;
    fetching: boolean;
    error?: Error;
  };
}): UseOfferSeenAtViewModel => ({
  data: data?.offerUpdateSeenAt?.offer,
  isLoading: fetching,
  error,
});

const useSeenAtOffer = (offerId: string) => {
  const { data, fetching, error, updateOfferSeenAt } = useOfferSeenAtUpdate();

  const viewModel = toViewModel({
    offer: { data, fetching, error },
  });

  useEffect(() => {
    updateOfferSeenAt({
      input: { offerId },
    });
  }, [offerId, updateOfferSeenAt]);

  return {
    viewModel,
  };
};

export default useSeenAtOffer;
