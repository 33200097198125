import { Trans, useTranslation } from 'react-i18next';

import { Modal, Stack } from '@application/components';
import { Button } from '@application/components/buttons';

type CandidateHireModalProps = {
  onConfirm: (closeRequest: boolean) => void;
  onCancel: () => void;
};

const CandidateHireModal = ({
  onConfirm,
  onCancel,
}: CandidateHireModalProps) => {
  const { t } = useTranslation('candidates');
  const { t: tGlobal } = useTranslation();

  return (
    <Stack>
      <div className="whitespace-pre-line space-y-s-16 mb-s-16">
        <p>
          <Trans i18nKey="modal.contents.hireConfirmationQuestion" t={t} />
        </p>

        <ul className="space-y-s-16 list-disc list-inside">
          <li>
            <Trans i18nKey="modal.contents.hireConfirmationOpenRequest" t={t} />

            <Modal.Action className="mt-s-24">
              <Button type="button" primary onClick={() => onConfirm(false)}>
                {t('modal.button.keepOpen')}
              </Button>
            </Modal.Action>
          </li>
          <li>
            <Trans
              i18nKey="modal.contents.hireConfirmationCloseRequest"
              t={t}
            />

            <Modal.Action className="mt-s-24">
              <Button type="button" primary onClick={() => onConfirm(true)}>
                {t('modal.button.closeRequest')}
              </Button>
            </Modal.Action>
          </li>
        </ul>
      </div>
      <Modal.Action className="mt-s-24">
        <Button type="button" onClick={onCancel}>
          {tGlobal('button.cancel')}
        </Button>
      </Modal.Action>
    </Stack>
  );
};

export default CandidateHireModal;
