import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Badge } from '@application/components';

type OfferStatusProps = {
  seenAt?: Date | null;
  updatedAt: Date;
};

const OfferState = ({ seenAt, updatedAt }: OfferStatusProps) => {
  const { t } = useTranslation();

  const getBadge = useMemo(() => {
    if (!seenAt) {
      return <Badge value={t('states.new')} modifier="badge-info" />;
    }

    if (new Date(seenAt) < new Date(updatedAt)) {
      return <Badge value={t('states.updated')} modifier="badge-warning" />;
    }

    return <span className="min-h-[2rem]" />;
  }, [seenAt, t, updatedAt]);

  return getBadge;
};

export default OfferState;
