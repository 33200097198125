import {
  ColumnDef,
  createColumnHelper,
  OnChangeFn,
  PaginationState,
  SortingState,
} from '@tanstack/react-table';
import { TFunction } from 'i18next';
import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, Link } from 'react-router-dom';

import { Badge, BadgeList } from '@application/components/badge';
import { ButtonLinkCounter } from '@application/components/buttons';
import { ButtonLink } from '@application/components/links';
import { RequestTypeBadges } from '@application/components/request-type-badges';
import { TooltipEllipsis } from '@application/components/tooltip-ellipsis';
import { PrivatePage, RootPrivatePage } from '@application/enums/pagesUrl';
import { usePagination } from '@application/hooks';
import { mapDescriptions } from '@application/utils';
import {
  OperationTerritory,
  RecruitmentOpportunitiesFilters,
  RecruitmentOpportunity,
  RequestsSortBy,
  RequestsSortDirective,
  SortDirection,
} from '@domain/graphql.types';
import { flattenEdges } from '@utils/data-utils';
import {
  getDiffFromNow,
  getHoursSince,
  isOverDeadline,
} from '@utils/date-utils';
import { extractLanguage, getLocalizedDescription } from '@utils/i18n-utils';

import useGetRecruitmentOpportunities from './useGetRecruitmentOpportunities';

const columnIds = {
  jobSpecialtyCode: 'jobSpecialtyCode',
  jobExperienceLevel: 'jobExperienceLevel',
  activitySector: 'activitySector',
  type: 'type',
  operationTerritory: 'operationTerritory',
  receivingOfferDeadline: 'receivingOfferDeadline',
  action: 'action',
  createdAt: 'createdAt',
} as const;

const columnHelper = createColumnHelper<RecruitmentOpportunity>();

const getFormattedDate = (
  date: string,
  tGlobal: TFunction<'translation', undefined>
) => {
  const result = getDiffFromNow(date);
  if (getDiffFromNow(date)?.length === 0) {
    return tGlobal(`date.today`);
  }
  return result
    ?.map((d) =>
      tGlobal(`date.options.${d.unit}`, {
        count: d.value,
      })
    )
    .join(' ');
};

const getColumns = (
  language: string,
  t: TFunction<'translation', undefined>,
  tGlobal: TFunction<'translation', undefined>
) => {
  const columns: ColumnDef<RecruitmentOpportunity, any>[] = [
    columnHelper.accessor(
      (row) =>
        getLocalizedDescription(row.jobSpecialty?.descriptions, language),
      {
        id: columnIds.jobSpecialtyCode,
        cell: (info) => (
          <div>
            {getHoursSince(info.row.original.createdAt) < 72 ? (
              <Badge value={t('column.new')} modifier="badge-info" />
            ) : undefined}
            <Link
              to={generatePath(PrivatePage.RECRUITMENT_OPPORTUNITY_DETAILS, {
                id: info.row.original.id,
              })}
              className="hover:underline ml-s-4"
            >
              <span>{info.getValue() || '-'}</span>
            </Link>
          </div>
        ),

        header: () => t('column.job'),
        size: 320,
        minSize: 320,
        maxSize: undefined,
        sortDescFirst: true, // Because react-table people can't be bother to fix a simple issue, I need to do this https://github.com/TanStack/table/issues/4289
      }
    ),
    columnHelper.accessor((row) => row.jobExperienceLevelMin, {
      id: columnIds.jobExperienceLevel,
      cell: (info) => <span>{info.getValue() || '-'}</span>,
      header: () => t('column.experience'),
      size: 130,
      minSize: 130,
      maxSize: 130,
      enableSorting: false,
    }),
    columnHelper.accessor((row) => row.accountSector, {
      id: columnIds.activitySector,
      cell: (info) => {
        const accountSectorLabel = getLocalizedDescription(
          info.getValue()?.descriptions,
          language
        );

        return (
          <TooltipEllipsis toolTip={accountSectorLabel || ''}>
            <div className="max-w-[11.25rem]">{accountSectorLabel || '-'}</div>
          </TooltipEllipsis>
        );
      },
      header: () => t('column.activitySector'),
      size: 180,
      minSize: 180,
      maxSize: 180,
      enableSorting: false,
    }),
    columnHelper.accessor((row) => row.types, {
      id: columnIds.type,
      cell: (info) => <RequestTypeBadges types={info.getValue()} />,
      header: () => t('column.type'),
      size: 122,
      minSize: 122,
      maxSize: 122,
      enableSorting: false,
    }),
    columnHelper.accessor((row) => row.operationTerritories, {
      id: columnIds.operationTerritory,
      cell: (info) => {
        const operationTerritories = mapDescriptions(
          info
            .getValue()
            ?.map((territory: OperationTerritory) => territory.descriptions) ||
            [],
          language
        );

        return <BadgeList values={operationTerritories} />;
      },
      header: () => t('column.operationTerritory'),
      size: 272,
      minSize: 272,
      maxSize: undefined,
      enableSorting: false,
    }),
    columnHelper.accessor((row) => row.receivingOfferDeadline, {
      id: columnIds.receivingOfferDeadline,
      cell: (info) => {
        if (!info.getValue()) return '-';
        return (
          <span>
            {isOverDeadline(
              info.getValue().toString(),
              DateTime.now().toString()
            )
              ? tGlobal('date.isOverDeadline')
              : getFormattedDate(info.getValue(), tGlobal)}
          </span>
        );
      },
      header: () => t('column.timeLeft'),
      size: 130,
      minSize: 130,
      maxSize: undefined,
      sortDescFirst: true, // Because react-table people can't be bother to fix a simple issue, I need to do this https://github.com/TanStack/table/issues/4289
    }),
    columnHelper.accessor((row) => row.id, {
      id: columnIds.action,
      cell: (info) => (
        <div className="flex items-center justify-end gap-s-8">
          <ButtonLinkCounter
            iconName="ri-question-answer-line"
            to={generatePath(PrivatePage.RECRUITMENT_OPPORTUNITY_CHAT, {
              id: info.row.original.id,
            })}
            from={generatePath(RootPrivatePage.RECRUITMENT_OPPORTUNITIES)}
            size="xSmall"
          />

          <ButtonLink
            to={generatePath(PrivatePage.RECRUITMENT_OPPORTUNITY_OFFER_CREATE, {
              id: info.getValue(),
            })}
            size="x-small"
            className="py-s-0 whitespace-nowrap mr-s-16 animate-none"
          >
            {t('actions.makeOffer')}
          </ButtonLink>
        </div>
      ),
      header: () => null,
      enableSorting: false,
      size: 180,
      minSize: 180,
      maxSize: 180,
    }),
  ];

  return columns;
};

type UseRecruitmentOpportunitiesListProps = {
  columnIds: typeof columnIds;
  columns: ColumnDef<RecruitmentOpportunity>[];
  count?: number;
  currentPage?: PaginationState;
  onPaginationChange?: OnChangeFn<PaginationState>;
  onPaginationReset?: () => void;
  onSortingChange: OnChangeFn<SortingState>;
  recruitmentOpportunities: RecruitmentOpportunity[];
  sorting: SortingState;
  isLoading: boolean;
};

const stringSortToEnum = (by: string, language: string): RequestsSortBy => {
  const extractedLanguage = extractLanguage(language);

  switch (by) {
    case columnIds.receivingOfferDeadline:
      return RequestsSortBy.Deadline;
    case columnIds.jobSpecialtyCode:
      return extractedLanguage === 'en'
        ? RequestsSortBy.JobSpecialtyEn
        : RequestsSortBy.JobSpecialtyFr;
    case columnIds.createdAt:
      return RequestsSortBy.Date;
    default:
      return extractedLanguage === 'en'
        ? RequestsSortBy.JobSpecialtyEn
        : RequestsSortBy.JobSpecialtyFr;
  }
};

const mapSorting = (
  sorting: SortingState,
  language: string
): RequestsSortDirective[] =>
  sorting.map((s) => ({
    direction: s.desc ? SortDirection.Desc : SortDirection.Asc,
    by: stringSortToEnum(s.id, language),
  }));

export const useRecruitmentOpportunitiesList = (
  filters?: RecruitmentOpportunitiesFilters
): UseRecruitmentOpportunitiesListProps => {
  const [sorting, setSorting] = useState<SortingState>([
    { id: columnIds.createdAt, desc: true },
  ]);
  const { t, i18n } = useTranslation('recruitment', {
    keyPrefix: 'opportunity.list',
  });
  const { t: tGlobal } = useTranslation();

  const {
    pagination,
    currentPage,
    handlePaginationChange,
    setCursors,
    resetPagination,
  } = usePagination();

  const {
    viewModel: { data, isLoading },
  } = useGetRecruitmentOpportunities(
    mapSorting(sorting, i18n.language),
    filters,
    pagination
  );

  useEffect(() => {
    setCursors({
      startCursor:
        data?.recruitmentOpportunities.page.pageInfo?.startCursor || undefined,
      endCursor:
        data?.recruitmentOpportunities.page.pageInfo?.endCursor || undefined,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.recruitmentOpportunities.page.pageInfo]);

  useEffect(() => {
    resetPagination();
  }, [sorting, resetPagination]);

  const columns = getColumns(i18n.language, t, tGlobal);

  return {
    columnIds,
    columns: columns as ColumnDef<RecruitmentOpportunity>[],
    count: data?.recruitmentOpportunities.pageData?.count,
    currentPage,
    onPaginationChange: handlePaginationChange,
    onPaginationReset: resetPagination,
    onSortingChange: setSorting,
    recruitmentOpportunities: flattenEdges(
      data?.recruitmentOpportunities.page.edges?.slice() || []
    ),
    sorting,
    isLoading,
  };
};
