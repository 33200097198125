import { FieldErrors, UseFormSetValue, UseFormWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import { Button } from '@application/components';
import { Cluster, Stack } from '@application/components/container-layouts';
import { RootPrivatePage } from '@application/enums/pagesUrl';

import { RequestFormFields } from '../schema';

type AntiGhostingProps = {
  setValue: UseFormSetValue<RequestFormFields>;
  watch: UseFormWatch<RequestFormFields>;
  errors: FieldErrors<RequestFormFields>;
};

const AntiGhosting = ({ setValue, watch, errors }: AntiGhostingProps) => {
  const { t } = useTranslation('requests');
  const { t: tGlobal } = useTranslation();
  const antiGhostingConsent = watch('antiGhostingConsent');
  const navigate = useNavigate();

  return (
    <Stack space={16} className="mb-s-48">
      {antiGhostingConsent ? (
        <div className="h5 text-center">
          <span>{t('antiGhosting.confirmed')}</span>{' '}
          <span className="font-bold">{t('antiGhosting.confirmedStatus')}</span>
        </div>
      ) : (
        <>
          <h3 className="font-bold text-center">{t('antiGhosting.title')}</h3>
          <p className="whitespace-pre-wrap pr-s-32 pl-s-32">
            {t('antiGhosting.description')}
          </p>

          <Cluster justify="end" space={8}>
            <Button
              onClick={() => {
                navigate(RootPrivatePage.REQUESTS);
              }}
            >
              {t('antiGhosting.cancel')}
            </Button>

            <Button
              primary
              onClick={() => {
                setValue('antiGhostingConsent', true, {
                  shouldValidate: true,
                  shouldDirty: true,
                });
              }}
            >
              {t('antiGhosting.understand')}
            </Button>
          </Cluster>

          {errors.antiGhostingConsent?.message && (
            <span className="flex justify-end text-error-content  border-error-content">
              {tGlobal(errors.antiGhostingConsent.message)}
            </span>
          )}
        </>
      )}
    </Stack>
  );
};

export default AntiGhosting;
