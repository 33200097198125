import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { UserContext } from '@application/context';

import ChangePassword from './components/ChangePassword';
import TermsAndConditions from './components/TermsAndConditions';
import VerifyEmail from './components/VerifyEmail';

const SecurityManagementPage = () => {
  const { t } = useTranslation('user', {
    keyPrefix: 'tabs',
  });

  const { isEmailVerified } = useContext(UserContext);

  return (
    <div
      className="flex flex-col"
      aria-labelledby="tab-security"
      role="tabpanel"
    >
      <h1 className="sr-only border-stroke-light">{t('security')}</h1>

      {/* To uncomment when the modifications of KEH-1493 are done
      
      <UpdateEmail /> 

      <hr className="my-s-32 border-stroke-light" />

      */}

      <ChangePassword />

      {!isEmailVerified && (
        <>
          <hr className="my-s-32 border-stroke-light" />
          <VerifyEmail />
        </>
      )}

      <hr className="my-s-32 border-stroke-light" />

      <TermsAndConditions />
    </div>
  );
};

export default SecurityManagementPage;
