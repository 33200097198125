import { useCandidateRequestChatRooms } from '@domain/chat';
import { CandidateRequestChatRoomsInput } from '@domain/graphql.types';

import { toViewModel } from '../../view-models/chat-room/get-candidate-request-chat-room.vm';

const useGetCandidateRequestChatRooms = (
  input: CandidateRequestChatRoomsInput
) => {
  const { data, fetching, error, reexecuteQuery } =
    useCandidateRequestChatRooms({
      input,
    });

  const viewModel = toViewModel({
    candidateRequestChatRooms: {
      data,
      fetching,
      error,
    },
  });

  return {
    viewModel,
    refetchCandidateRequestChatRooms: reexecuteQuery,
  };
};

export default useGetCandidateRequestChatRooms;
