import { useTranslation } from 'react-i18next';

import MessageDisplay from '@application/components/messageDisplay/MessageDisplay';
import { Table } from '@application/components/table';

import { useOfferCandidatesList } from './hooks/useOfferCandidatesList';

export type CandidatesSummaryProps = {
  offerId: string;
  noCandidatesExplaination?: string | null;
};

const CandidatesSummary = ({
  offerId,
  noCandidatesExplaination,
}: CandidatesSummaryProps) => {
  const { t } = useTranslation('offers', { keyPrefix: 'viewOffer' });

  const { columns, offerCandidates, isLoading } = useOfferCandidatesList({
    offerId,
  });

  return offerCandidates.length > 0 ? (
    <Table data={offerCandidates} columns={columns} isLoading={isLoading} />
  ) : (
    noCandidatesExplaination && (
      <MessageDisplay
        label={t('noCandidatesExplaination')}
        text={noCandidatesExplaination || undefined}
      />
    )
  );
};

export default CandidatesSummary;
