import { gql } from '@urql/core';

import { DescriptionsFragment } from '@infrastructure/graphql/descriptions';

const OfferCandidate = gql`
  ${DescriptionsFragment}

  query OfferCandidate($offerCandidateGetInput: OfferCandidateGetInput!) {
    offerCandidate(offerCandidateGetInput: $offerCandidateGetInput) {
      offerCandidate {
        id
        allowedRemoteDays
        annualSalaryMax
        annualSalaryMin
        canWorkInCanadaOrQuebec
        citiesSubRegions
        cvUrl
        email
        emailDisplay
        experience
        feedback
        firstName
        firstNameDisplay
        fullTimeAvailability
        hourlyRateMax
        hourlyRateMin
        hoursPerWeek
        jobDurationInMonths
        jobTypeCode
        lastName
        lastNameDisplay
        metAt
        otherPaymentTypes
        partTimeAvailability
        paymentFrequencyCode
        remunerationAmount
        requestTypes
        schedules
        softSkills
        specialty
        studyField
        status
        privateNotes
        educationLevel {
          code
          descriptions {
            ...DescriptionsFragment
          }
        }
        jobMode {
          code
          descriptions {
            ...DescriptionsFragment
          }
        }
        jobSpecialty {
          code
          descriptions {
            ...DescriptionsFragment
          }
        }
        operationTerritories {
          descriptions {
            ...DescriptionsFragment
          }
        }
        otherFilesUrls {
          link
          fileName
        }
      }
    }
  }
`;

export default OfferCandidate;
