import React, { ForwardedRef, ReactNode, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { cn } from '@utils/lib-utils';
import { randomString } from '@utils/math-utils';

import { HelperText, Label } from '../form';
import { Select, SelectProps } from './select';

type SelectFieldProps = {
  /**
   * Provide the text that will be read by a screen reader
   * when visiting this control
   */
  label?: ReactNode;
  /**
   * Provide the text that is used alongside the `<input>` for additional help
   * or as a validation message on input error
   */
  helperText?: string;
  /**
   * Specify whether the `<label>` should be visually hidden
   */
  hideLabel?: boolean;
  /**
   * Enable select all functionality for multiple select
   */
  selectAll?: boolean;
} & SelectProps &
  React.ComponentPropsWithRef<'div'>;

type Ref = HTMLDivElement;

const SelectField = React.forwardRef<Ref, SelectFieldProps>(
  (
    {
      options,
      label,
      id,
      name,
      value,
      defaultValue,
      placeholder,
      clearable,
      searchable,
      multiple,
      disabled,
      invalid,
      autoFocus,
      hideLabel,
      helperText,
      className: customClassName,
      controlClassName,
      limitTags,
      menuPlacement,
      smallMenu,
      cacheOptions,
      defaultOptions,
      isLoading,
      loadOptions,
      hideDropdownIndicator,
      hideNoOptions,
      renderNoOptionsContent,
      renderLoadingContent,
      renderMenuFooterContent,
      onInputChange,
      onChange,
      onFocus,
      onBlur,
      selectAll,
      ...rest
    }: SelectFieldProps,
    ref: ForwardedRef<Ref>
  ) => {
    /**
     * Note: use a React.useMemo here in order to avoid generating
     * a new random string at each render
     */
    const selectId = useMemo(() => id || randomString(), [id]);
    const helperTextId = useMemo(() => randomString(), []);
    const { t } = useTranslation();

    const className = cn('', customClassName);

    const optionsProps = loadOptions
      ? { loadOptions, defaultOptions, isLoading }
      : { options };

    const handleSelectAll = useCallback(() => {
      if (!onChange || !options || !Array.isArray(options)) return;

      const allOptions = options.flatMap((option) => {
        if ('options' in option) {
          return option.options.map((opt) => ({
            value: opt.value,
            label: opt.label,
          }));
        }

        return {
          value: option.value,
          label: option.label,
        };
      });

      onChange(allOptions);
    }, [onChange, options]);

    const isAllSelected = useMemo(
      () =>
        Array.isArray(value) &&
        Array.isArray(options) &&
        value.length === options.length,
      [value, options]
    );

    return (
      <div ref={ref} className={className} {...rest}>
        <Label
          htmlFor={selectId}
          disabled={disabled}
          invalid={invalid}
          className={hideLabel ? 'sr-only' : undefined}
        >
          {label}
        </Label>

        <div className="relative">
          <Select
            id={selectId}
            aria-describedby={helperText ? helperTextId : undefined}
            name={name}
            value={value}
            controlClassName={controlClassName}
            defaultValue={defaultValue}
            clearable={clearable}
            searchable={searchable}
            multiple={multiple}
            disabled={disabled}
            invalid={invalid}
            autoFocus={autoFocus}
            onInputChange={onInputChange}
            onChange={onChange}
            onFocus={onFocus}
            onBlur={onBlur}
            menuPlacement={menuPlacement}
            placeholder={placeholder}
            limitTags={limitTags}
            hideDropdownIndicator={hideDropdownIndicator}
            hideNoOptions={hideNoOptions}
            renderNoOptionsContent={renderNoOptionsContent}
            renderLoadingContent={renderLoadingContent}
            smallMenu={smallMenu}
            renderMenuFooterContent={renderMenuFooterContent}
            {...optionsProps}
          />
        </div>
        {multiple &&
          selectAll &&
          Array.isArray(options) &&
          options.length > 0 &&
          !isAllSelected && (
            <div>
              <button
                type="button"
                onClick={handleSelectAll}
                className="p-s-4 float-right text-16 font-bold"
              >
                {t('button.selectAll')}
              </button>
            </div>
          )}
        {helperText && (
          <HelperText id={helperTextId} invalid={invalid}>
            {helperText}
          </HelperText>
        )}
      </div>
    );
  }
);

export default SelectField;
