import { useCallback, useEffect, useState } from 'react';
import { ActionMeta, GroupBase, GroupProps } from 'react-select';

import { SECONDARY_STYLES } from '@application/components/buttons/constants';
import { cn } from '@utils/lib-utils';
import { randomString } from '@utils/math-utils';

import { OptionType } from './types';

export default function Group({
  label,
  children,
  data,
  selectProps,
}: GroupProps<OptionType, boolean, GroupBase<OptionType>>) {
  const [isChecked, setIsChecked] = useState(false);
  const inputId = randomString();

  useEffect(() => {
    if (data.options && selectProps.value) {
      const allOptionsSelected = data.options.every(
        (option) =>
          Array.isArray(selectProps.value) &&
          selectProps.value.some((selectedOption) => selectedOption === option)
      );
      setIsChecked(allOptionsSelected);
    }
  }, [selectProps.value, data.options]);

  const handleClick = useCallback(() => {
    if (selectProps.onChange && data.options) {
      const currentSelections = Array.isArray(selectProps.value)
        ? selectProps.value
        : [];

      const actionMeta: ActionMeta<OptionType> = {
        action: isChecked ? 'deselect-option' : 'select-option',
        option: data.options[0],
        name: selectProps.name,
      };

      const newSelections = isChecked
        ? currentSelections.filter(
            (selection) => !data.options.some((option) => option === selection)
          )
        : [...currentSelections, ...data.options];

      selectProps.onChange(newSelections, actionMeta);
    }
  }, [selectProps, data.options, isChecked]);

  const labelClassName = cn(
    'flex items-center justify-start cursor-pointer',
    'px-s-8 py-s-4 gap-s-10',
    SECONDARY_STYLES,
    isChecked && 'bg-secondary'
  );

  const checkboxClassName = cn(
    'mr-s-8 checkbox w-s-18 h-s-18 rounded-none border-2 border-primary',
    isChecked && 'checkbox-primary'
  );

  const labelTextClassName = 'font-bold text-18';

  return (
    <div>
      <div
        role="button"
        tabIndex={0}
        className={labelClassName}
        onClick={handleClick}
        onKeyDown={handleClick}
      >
        <input
          type="checkbox"
          id={inputId}
          checked={isChecked}
          className={checkboxClassName}
          readOnly
          aria-hidden="true"
        />
        <span className={labelTextClassName}>{label}</span>
      </div>
      <div className="pl-s-16">{children}</div>
    </div>
  );
}
