import { gql } from '@urql/core';

const MutationOfferUpdateSeenAt = gql`
  mutation OfferUpdateSeenAt($input: OfferInput!) {
    offerUpdateSeenAt(input: $input) {
      offer {
        id
        requestId
        seenAt
      }
    }
  }
`;

export default MutationOfferUpdateSeenAt;
