import { gql } from '@urql/core';

const OfferCandidates = gql`
  query OfferCandidates($filterBy: OfferCandidatesFilters!) {
    offerCandidates(filterBy: $filterBy) {
      results {
        id
        createdAt
        cvUrl
        experience
        feedback
        metAt
        status
        notes
        privateNotes
        offerId
        updatedAt
        firstName
        firstNameDisplay
        lastName
        lastNameDisplay
        rejectionReason
        rejectionExplanation
      }
    }
  }
`;

export default OfferCandidates;
