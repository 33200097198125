import { UseQueryExecute } from 'urql';

import {
  CandidateRequestChatRoom,
  CandidateRequestChatRoomsInput,
} from '@domain/graphql.types';

import useGetCandidateRequestChatRooms from './useGetCandidateRequestChatRooms';

type UseCandidateRequestChatRoomsListProps = {
  count?: number;
  candidateRequestChatRooms: CandidateRequestChatRoom[];
  isLoading: boolean;
  refetchCandidateRequestChatRooms: UseQueryExecute;
};

const useCandidateRequestChatRoomsList = (
  input: CandidateRequestChatRoomsInput
): UseCandidateRequestChatRoomsListProps => {
  const {
    viewModel: { data, isLoading },
    refetchCandidateRequestChatRooms,
  } = useGetCandidateRequestChatRooms(input);

  return {
    count: data?.candidateRequestChatRooms.totalCount,
    candidateRequestChatRooms:
      data?.candidateRequestChatRooms.results.slice() || [],
    isLoading,
    refetchCandidateRequestChatRooms,
  };
};

export default useCandidateRequestChatRoomsList;
