import { CombinedError } from 'urql';

import { extractErrorCodes } from '@application/utils';
import ViewModel from '@application/view-model';
import { Mutation } from '@domain/graphql.types';

type ToViewModel = {
  candidate: {
    data: Pick<Mutation, 'updateCandidateStatusToInterview'> | undefined;
    fetching: boolean;
    error: CombinedError | undefined;
  };
};

export const toViewModel = ({
  candidate: { data, fetching, error },
}: ToViewModel): ViewModel<
  Pick<Mutation, 'updateCandidateStatusToInterview'> | undefined
> =>
  new ViewModel<Pick<Mutation, 'updateCandidateStatusToInterview'> | undefined>(
    {
      isLoading: fetching,
      errorCodes: extractErrorCodes(error),
      data,
    }
  );
