import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import { PageLoader } from '@application/components';
import { AccountContext } from '@application/context';
import { getLocalizedDescription } from '@utils/i18n-utils';

import { useGetCandidateRequest } from '../candidate/request/hooks';
import CandidateRequestChatContent from './CandidateRequestChatContent';

const CandidateRequestChat = () => {
  const { account } = useContext(AccountContext);
  const { id = '' } = useParams();
  const { i18n } = useTranslation();

  const {
    viewModel: {
      data: candidateRequestData,
      isLoading: isCandidateRequestLoading,
    },
  } = useGetCandidateRequest(id);

  return !isCandidateRequestLoading && candidateRequestData && account ? (
    <CandidateRequestChatContent
      candidateRequestId={id}
      accountId={account.id}
      title={
        getLocalizedDescription(
          candidateRequestData.jobSpecialty?.descriptions,
          i18n.language
        ) ?? ''
      }
      subtitle={`${candidateRequestData.firstName} ${candidateRequestData.lastName}`}
    />
  ) : (
    <PageLoader />
  );
};

export default CandidateRequestChat;
