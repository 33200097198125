import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router';

import { AccountContext, AlertContext } from '@application/context';
import { PrivatePage } from '@application/enums/pagesUrl';
import { useRequestSaveAndPublish } from '@domain/request';

import { toViewModel } from '../view-models/save-and-publish-request.vm';

const useSaveAndPublishRequest = () => {
  const { data, fetching, error, saveAndPublish } = useRequestSaveAndPublish();

  const { t } = useTranslation('requests', {
    keyPrefix: 'messages',
  });

  const navigate = useNavigate();
  const { setAlertContent } = useContext(AlertContext);
  const { refreshAccount } = useContext(AccountContext);

  const viewModel = toViewModel({
    request: { data, fetching, error },
  });

  useEffect(() => {
    if (!error) {
      return;
    }

    setAlertContent({
      action: t('saveAndPublishError'),
      modifier: 'alert-error',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  useEffect(() => {
    if (data?.requestUpdate?.request) {
      setAlertContent({
        action: t('saveAndPublishSuccess'),
        modifier: 'alert-success',
      });

      refreshAccount();

      navigate(
        generatePath(PrivatePage.REQUEST_DETAILS, {
          id: data.requestUpdate.request.id,
        }),
        { state: { openMatchingCandidateOpportunitiesModal: true } }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.requestUpdate?.request]);

  return { saveAndPublish, viewModel };
};

export default useSaveAndPublishRequest;
