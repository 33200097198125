import { gql } from 'urql';

const CandidateRequestChatRooms = gql`
  query CandidateRequestChatRooms($input: CandidateRequestChatRoomsInput!) {
    candidateRequestChatRooms(input: $input) {
      results {
        id
        candidateRequestId
        enterpriseAccountId
        agencyAccountId
        creationDate
        isAgencyAnonym
        enterpriseLastMessage {
          message
          sentAt
        }
        candidateOfferData {
          rank
          hasMadeOffer
          isUnderNegotiation
        }
        enterpriseName
        agencyName
      }
      totalCount
    }
  }
`;

export default CandidateRequestChatRooms;
