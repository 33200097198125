import { useTranslation } from 'react-i18next';
import { generatePath } from 'react-router';

import { ButtonLink, Link } from '@application/components';
import { Switch } from '@application/components/switch';
import { PrivatePage } from '@application/enums/pagesUrl';
import { Offer, OfferStatusTypeCode, Request } from '@domain/graphql.types';
import { cn } from '@utils/lib-utils';

import OfferState from './OfferState';

const renderAccountNameElement = ({
  account: { name: accountName },
  accountId,
}: Offer) => (
  <Link
    className="text-16"
    to={generatePath(PrivatePage.ORGANIZATION_PUBLIC_PROFILE, {
      id: accountId,
    })}
  >
    {accountName}
  </Link>
);

type TableHeaderProps = {
  request: Request;
  // comparing?: boolean;
  offers: readonly Offer[];
  checkedOffers: string[];
  // onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

const TableHeader = ({
  request,
  // comparing,
  offers,
  checkedOffers,
  // onChange,
}: TableHeaderProps) => {
  const { t } = useTranslation('offers', {
    keyPrefix: 'comparison',
  });

  const hasAnyOfferStatus = offers.some(
    (offer) =>
      !offer.seenAt ||
      (offer.seenAt && new Date(offer.seenAt) < new Date(offer.updatedAt))
  );

  return (
    <thead>
      <tr>
        <th className="w-s-256 min-w-[16rem] max-w-[16rem] text-left pl-s-16 pt-s-28 h-s-72">
          {t('information.agencyName')}
        </th>

        {offers.map((offer: Offer) => (
          <th
            key={`tableHeader-${offer.id}`}
            data-testid="TEST-offer-table-header"
            className={cn('h-s-72 border-x-2 border-t-2 border-transparent', {
              'border-primary': checkedOffers.includes(offer.id),
            })}
          >
            <Switch
              fallback={
                <div className="flex flex-col items-center">
                  {/* <Checkbox
                    id={offer.id}
                    checked={checkedOffers.includes(offer.id)}
                    disabled={comparing}
                    onChange={onChange}
                    labelDirection="bottom"
                    label={renderAccountNameElement(offer)}
                    name={offer.account.name}
                    value={String(checkedOffers.includes(offer.id))}
                    labelTextClassName="font-semibold"
                  /> */}

                  {hasAnyOfferStatus && (
                    <OfferState
                      seenAt={offer.seenAt}
                      updatedAt={offer.updatedAt}
                    />
                  )}

                  <span className="font-semibold text-14">
                    {renderAccountNameElement(offer)}
                  </span>

                  <ButtonLink
                    size="small"
                    className="mt-s-8 justify-center min-w-fit w-fit"
                    to={generatePath(PrivatePage.OFFER_DETAILS, {
                      id: request?.id || null,
                      offerId: offer?.id,
                    })}
                  >
                    {t('buttons.offerDetails')}
                  </ButtonLink>
                </div>
              }
            >
              <Switch.Match
                when={offer.status === OfferStatusTypeCode.Rejected}
              >
                <div className="flex flex-col">
                  <i className="text-24 leading-initial font-normal text-error-content ri-close-circle-line py-s-6" />

                  {renderAccountNameElement(offer)}

                  <span className="font-normal text-14 pt-s-8">
                    {offer.user.email}
                  </span>
                </div>
              </Switch.Match>

              <Switch.Match
                when={offer.status === OfferStatusTypeCode.Accepted}
              >
                <div className="flex flex-col">
                  <i className="text-24 leading-initial font-normal text-success-content ri-shake-hands-line py-s-6" />

                  {renderAccountNameElement(offer)}

                  <span className="font-normal text-14 pt-s-8">
                    {offer.user.email}
                  </span>
                </div>
              </Switch.Match>
            </Switch>
          </th>
        ))}
      </tr>
    </thead>
  );
};

export default TableHeader;
